@import "colors";
@import "mixins";

.page-footer {
    // border-top: 1px solid rgb(243, 243, 243);
    background: #f7f6f6;
    padding: 4rem 16rem 10rem 16rem;
    font-weight: 300;
    // top: -60px;

    img {
        //width: 5rem;
        height: 5rem;
        margin: 0 0 1rem -0.5rem;
    }

    @include breakpoint(tablet) {
        //  padding: 5rem 5rem;
        padding: 5rem 8rem 5rem 12rem;
    }

    @include breakpoint(mobile) {
        padding: 3rem 3.5rem;

        img {
            height: 5.5rem;
        }
    }

    .kn-heading {
        margin-bottom: 1rem;
    }

    table {
        tr {
            td {
                font-size: 1.6rem;
                padding: 0.5rem 0;
            }
        }
    }

    ul {
        list-style-type: none;

        li {
            font-size: 1.6rem;
            padding: 0.5rem 0;
            cursor: pointer;

            a {
                text-decoration: none;

                &:hover {
                    color: $primary-green;
                }
            }

            &.bold {
                font-weight: 700;
            }
        }
    }
}

.copyright-text {
    display: inline-block;
}

.page-post-footer {
    padding: 2rem 10rem;
    background: rgb(247, 246, 246);
    border-top: 2px solid #0000005e;
    // text-align: left;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    p {
        width: 68%;
        vertical-align: middle;
        font-size: 14px;
    }

    .follow-icons-container {
        float: right;
        // font-size: 20px;
        display: flex;
        //  justify-content: space-between;
        gap: 2px;

        button {
            background: transparent;
            border: none;
            // width: 40px;
            // height: 40px;

            img {
                height: 26px;
                width: 26px;

                &:hover {
                    filter: invert(100%);
                    -webkit-filter: invert(100%);
                    //  filter: invert(83%) sepia(6%) saturate(5336%) hue-rotate(33deg) brightness(87%) contrast(56%);
                    //  -webkit-filter: invert(83%) sepia(6%) saturate(5336%) hue-rotate(33deg) brightness(87%) contrast(56%);

                }
            }
        }

        @media (min-width: 901px) and (max-width: 1024px) {
            // float: left;
            // padding-top: 14px;
        }

        @include breakpoint(tablet) {
            // float: left;
            // padding-top: 14px;

            button {
                font-size: 6px;
            }
        }

        @media (min-width: 426px) and (max-width: 600px) {
            float: left;
            padding-top: 14px;

            button {
                font-size: 6px;
            }
        }

        @include breakpoint(mobile) {
            float: left;
            padding-top: 14px;

            button {
                font-size: 6px;
            }
        }

    }
}

.careers {
    &::after {
        content: "We are hiring :)";
        display: inline-block;
        background-color: $primary-brown;
        color: $white;
        padding: 2px 8px;
        margin-left: 1rem;
        border-radius: 4px;
        font-style: italic;
    }
}

// new footer css
.footer_details_text {
    font-size: 14px;
    font-weight: 500;
    width: 34%;
    padding-top: 4px;
    color: #0000007d;

    @include breakpoint(tablet) {
        width: 75%;
    }

    @include breakpoint(mobile) {
        width: 90%;
    }
}

.footer_link_text {
    h4 {
        font-size: 14px;
        font-weight: 600;
        color: #000000d9;
        padding-bottom: 8px;
    }

    p {
        font-size: 14px;
        padding-bottom: 8px;
        font-weight: 500;

        a {
            color: #0000007d;
            cursor: pointer;

            &:hover {
                color: #88ad49;
            }
        }
    }
}

.getApp_footer_section {
    top: -90px;
    width: 75vw;
    margin: 0 180px;
    background: #102e13;
    border-radius: 8px;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    gap: 60px;
    z-index: 10;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    h4 {
        color: white;
        font-size: 26px;
        font-weight: 600;
        padding: 0 40px;
        text-align: center;
    }
}

.get_mg_app_foter {
    text-align: center;
    margin-top: 10px;

    button {
        background: transparent;
        //  padding: 8px 12px 2px 12px;
        border: none;
    }

    img {
        width: 140px;
        max-width: 150px;
        display: inline-block;
    }

}

.get_mg_app_input_section_footer {
    text-align: center;
    position: relative;
    width: 380px;

    // margin: 60px auto;
    input {
        height: 48px;
        margin: 0px;
        width: 100%;
        outline: none;
        border: 1px solid #0000001a;
        border-radius: 8px;
        padding: 8px 8px 8px 14px;
        font-size: 14px;
    }

    button {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
        height: 48px;
        cursor: pointer;
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
        background: #B58A12;
        border-radius: 0px 8px 8px 0px;
        border: none;
        font-size: 16px;
        color: white;
        font-weight: 500;
        padding: 8px 20px;

        &:hover {
            background-color: #9d7915;

        }
    }

    p {
        color: white;
        text-align: left;
        margin: auto;
        padding: 4px 110px 4px 4px;

        a {
            text-decoration: underline;
        }
    }
}

.footer_playstore_rating {
    display: flex;
    align-items: center;
    margin-top: 16px;

    h5 {
        color: #a7c9af;
        font-size: 20px;
        font-weight: 500;
        padding: 0 40px;
        text-align: center;
        margin: auto;

        span {
            color: white;
            font-weight: 600;
        }

        img {
            width: 120px;
            max-width: 120px;
            display: inline-block;
            margin: -3px 0 3px 6px;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {

    .getApp_footer_section {
        width: 85vw;
        margin: auto;
        display: flex;
        flex-direction: row;
        top: -80px;

        h4 {
            font-size: 20px;

            padding: 0 16px;
        }
    }

    .get_mg_app_input_section_footer {
        width: 370px;
        margin-right: 30px;

        input {
            padding: 13px 6px 12px 6px;
            font-size: 12px;
            width: 90%;
        }

        button {
            font-size: 14px;
            font-weight: 500;
            padding: 12px 16px;
        }

        p {
            width: 90%;
            padding: 4px 60px 4px 4px;
        }
    }

    .page-post-footer {
        padding: 2rem 2rem;

        p {
            width: 64%;
        }
    }

    .footer_playstore_rating {
        h5 {
            font-size: 16px;
        }
    }
}

@media (min-width: 901px) and (max-width: 1024px) {
    .getApp_footer_section {
        width: 80vw;
        margin: auto;
        display: flex;
        flex-direction: row;
        top: -80px;
        gap: 10px;

        h4 {
            font-size: 16px;
            padding: 0 16px;
        }
    }

    .get_mg_app_input_section_footer {
        width: 380px;
        margin-right: 24px;

        input {
            padding: 10px 6px 10px 6px;
            font-size: 12px;
            width: 90%;
            height: 40px;
        }

        button {
            font-size: 14px;
            font-weight: 500;
            padding: 12px 16px;
            height: 40px;
        }

        p {
            width: 90%;
            padding: 4px 60px 4px 4px;
        }
    }

    .page-post-footer {
        padding: 2rem 4rem;

        p {
            width: 60%;
        }
    }
}
@media (min-width: 670px) and (max-width: 920px) {
    .footer_playstore_rating {
        h5 {
            font-size: 16px;
    
            img {
                margin: 4px 0 0px 0px;
            }
        }
    }
}

@include breakpoint(tablet) {
    .getApp_footer_section {
        width: 80vw;
        margin: auto;
        display: flex;
        flex-direction: row;
        top: -80px;
        gap: 10px;

        h4 {
            font-size: 16px;
            padding: 0 16px;
        }
    }

    .get_mg_app_input_section_footer {
        width: 380px;
        margin-right: 24px;

        input {
            padding: 10px 6px 10px 6px;
            font-size: 12px;
            width: 90%;
            height: 40px;
        }

        button {
            font-size: 14px;
            font-weight: 500;
            padding: 12px 16px;
            height: 40px;
        }

        p {
            width: 90%;
            padding: 4px 60px 4px 4px;
        }
    }

    .page-post-footer {
        padding: 2rem 5rem;

        p {
            width: 60%;
        }
    }

    .footer_playstore_rating {
        h5 {
            font-size: 16px;
        }
    }
}

@include breakpoint(phablet) {
    .getApp_footer_section {
        width: 86vw;
        top: -130px;
        margin: auto;
        gap: 40px;
        flex-direction: column;

        h4 {
            font-size: 24px;

            padding: 0 2px;
        }
    }

    .get_mg_app_input_section_footer {
        width: 90%;
        margin: auto;

        input {
            padding: 13px 6px 12px 6px;
            font-size: 12px;
            width: 100%;
        }

        button {
            font-size: 12px;
            font-weight: 500;
            padding: 12px 16px;
        }

        p {
            font-size: 10px;
            width: 100%;
            padding: 4px 60px 4px 4px;
        }
    }

    .footer_link_text {
        margin-top: 20px;
    }

    .page-post-footer {
        padding: 2rem 5rem;

        p {
            width: 100%;
        }
    }

}

@media (min-width: 600px) and (max-width: 767px) {
    .page-post-footer {
        padding: 2rem 5rem;

        p {
            width: 45%;
        }
    }

}

@include breakpoint(mobile) {
    .getApp_footer_section {
        width: 86vw;
        top: -160px;
        margin: auto;
        gap: 40px;
        flex-direction: column;

        h4 {
            font-size: 20px;

            padding: 0 2px;
        }
    }

    .get_mg_app_input_section_footer {
        width: 100%;

        input {
            padding: 13px 6px 12px 6px;
            font-size: 12px;
            width: 100%;
        }

        button {
            font-size: 12px;
            font-weight: 500;
            padding: 12px 16px;
        }

        p {
            width: 100%;
            padding: 4px 45px 4px 4px;
            font-size: 8px;
        }
    }

    .footer_link_text {
        margin-top: 20px;
    }

    .page-post-footer {
        padding: 2rem 5rem;

        p {
            width: 100%;
        }
    }
    .footer_playstore_rating {
        h5 {
            font-size: 16px;

            img {
                margin: 4px 0 0px 0px;
                width: 160px;
                max-width: 160px;
            }
        }
    }

}