.op-react-modal{
  position: relative;
  max-height: 95vh;
  height: 100%;
  overflow: hidden;
  //padding-top: 50px;

  &-title{
    flex: 3;
    font-weight: 700;
    color: #333;
    white-space: nowrap;
    margin-right: 25px;
  }
  &-header{
    background-color: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding-bottom: 5px;
  }
  &-body{
    overflow-y: auto;
    overflow-x: hidden;
    background-color: inherit;
    max-height: calc(100vh - 40px - 15px - 50px);
    height: 100%;
  }
  &-close-btn{
    height: 35px;
    width: 35px;
    background-color: #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    transition: all .3s ease-in-out;
    &:hover{
      background-color: #ccc;
    }
  }
}
