.mg-villageCard-page {
    background-color: #f6f6f7;

    &-wrapper {
        padding: 100px 0 24px 0;
        width: 350px;
        max-width: 95%;
        margin: auto;
    }
}

.card_details {
    margin: 6px 8px 10px 14px;
    display: flex;
    gap: 60px;
}

.card_details_subheading {
    padding: 4px 0px;
    color: #777373;
    font-size: 12px;
    font-weight: 500;
}

.card_details_subheading_info {
    font-size: 14px;
    padding: 0px 20px 0px 0px;
    text-transform: capitalize;
    color: black;
    word-break: break-all;
}

.card_details_membership_tier {
    text-align: center;
    width: 50%;
    margin: auto;

    p {
        font-size: 14px;
        font-weight: 600;
        color: white;
        border-radius: 20px;
        padding: 4px;

        i {
            padding-right: 5px;
        }
    }
}