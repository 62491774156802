@import "colors";
@import "mixins";

#kn-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    color: $dark;
    z-index: 201;
    font-size: 1.5em;
    //  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15); // to use old header uncomment this
    @include easing(0.4s, ease, 0s);

    //border-bottom: 1px solid rgb(209, 209, 209);
    .menu-btn {
        display: none;
    }

    .logo-container {
        img {
            @include easing(0.4s, ease, 0s);
        }
    }

    a.active {
        border-bottom: 2px solid $positive-green;
        color: black;
    }

    .link-container {
        // display: block; // to use old header comment out code till line no 33 to 42
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(phablet) {
            display: block;
            padding-top: 12px;
        }

        @include breakpoint(mobile) {
            display: block;
        }

        ul.main-list {
            display: table;
            //width: 100%;
            margin: 0;
            padding: 0;
            // margin-left: -16px;
            margin-top: 6px;
            //table-layout: fixed;
            // float: right;  //old header uncomment this

            //border: 1px solid black;
            @include breakpoint(phablet) {
                display: block;
            }

            @include breakpoint(mobile) {
                display: block;
                width: 100%; //old header comment this
            }

            li {
                display: table-cell;

                @include breakpoint(phablet) {
                    display: block;
                }

                @include breakpoint(mobile) {
                    display: block;
                }

                font-size: 16px;
                //color: $text-tertiary;
                color:#252525; //old header comment this
                font-weight: 600; //old header comment this
                cursor: pointer;
                text-align: center;
                //border: 1px solid black;
                transition: all 0.2s ease 0s;
                border-bottom: 1px solid transparent;
                font-weight: 500; //old header value is 400

                &:hover {
                    //border-bottom: 1px solid black;
                    color: $dark;
                }

                a {
                    color: #252525; //old header comment this css for anchor tag

                    &:hover {
                        color: #88ad49;
                    }

                }

                .dropdown-card-list {
                    display: block;
                    width: 200px;
                    position: absolute;
                    background: rgb(247, 245, 245);
                    top: 45px;
                    right: 0;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

                    li {
                        display: block;
                        padding: 8px 12px;
                        user-select: none;
                        -webkit-user-select: none;
                        text-align: left;

                        &:first-child {
                            border-bottom: 1px solid #ccc;
                        }

                        &:hover {
                            background: $primary-brown;
                            color: $light;
                            border-bottom: 1px solid $primary-brown;
                        }

                        a {
                            &:hover {
                                color: white;
                            }
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: -20px;
                        right: 20px;
                        border-style: solid;
                        border-width: 10px;
                        border-color: transparent transparent rgb(247, 245, 245) transparent;
                    }
                }
            }

            li.active {
                border-bottom: 1px solid black;
            }
        }
    }
}

.scroll-before {
    background: #ffffff;
    // padding: 20px 20px;
    padding: 20px 70px 4px 70px;
    color: $dark;
    font-size: 1.5em;

    .logo-container {
        img {
            @include easing(0.4s, ease, 0s);
            max-width: 200px;
        }
    }

    .link-container {
        ul {
            li {
                font-size: 14px;
                padding: 10px 10px;
            }

            li.active {
                border-bottom: 1px solid black;
            }
        }
    }

    @include breakpoint(tablet) {
        //old header comment this mediaquery
        padding: 20px 20px;
    }

    @include breakpoint(phablet) {
        padding: 20px 20px;
    }

    @include breakpoint(mobile) {
        padding: 20px 20px;
    }
}

.header-scroll {
    background: white;
    // padding: 15px 20px;
    padding: 15px 70px;
    color: $light;
    z-index: 100;
    font-size: 1.5em;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

    .logo-container {
        img {
            @include easing(0.4s, ease, 0s);
            max-width: 200px;
        }
    }

    .link-container {
        ul {
            li {
                padding: 5px 10px;
                //border: 1px solid black;
            }

            li.active {
                border-bottom: 1px solid black;
            }
        }
    }

    @include breakpoint(tablet) {
        //old header comment this mediaquery
        padding: 15px 20px;
    }

    @include breakpoint(phablet) {
        padding: 15px 20px;
    }

    @include breakpoint(mobile) {
        padding: 15px 20px;
    }
}

.vis {
    display: block;
}

.hide {
    display: block;
}

@media (max-width: 992px) {
    #kn-header {
        .logo-container {
            max-width: 170px;

            img {
                max-width: 100%;
            }
        }

        .menu-btn {
            display: block;
            position: absolute;
            right: 10px;
            top: 0;
            color: $primary-brown;
            background-color: transparent;
            border: none;
            font-size: 2rem;
            z-index: 100;
        }

        .link-container {
            ul {
                // width: 100%; //old header uncomment this
                width: 65%;
                margin: 30px 0 0 0;
                padding: 0;
                table-layout: fixed;
                float: none;

                li {
                    display: block;
                    font-size: 14px;
                    cursor: pointer;
                    text-align: center;
                    padding: 15px 10px;
                    border-bottom: 1px solid transparent;

                    &:hover {
                        border-bottom: 1px solid transparent;
                        color: $dark;
                        background: white;
                    }
                }

                li.active {
                    border-bottom: 1px solid black;
                }
            }
        }
    }

    .vis {
        display: block;
    }

    .hid {
        display: none !important;
    }
}

// new app download btn  //old header comment this
.header_appdownload_btn {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    background-color: #88AD49;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 24px 12px 24px;
    border-radius: 20px;
    height: 40px;

    &:hover {
        background-color: #688832;

    }

    @include breakpoint(tablet) {
        margin: auto;
    }
}

// header label for asp nd carrers
.header_comming_soon {
    text-align: center;
    color: rgb(136, 173, 73);
    margin: auto;
    width: 93vw;

    button {
        border: none;
        background: rgb(240, 243, 219);
        border-radius: 20px;
        padding: 4px 12px;
        cursor: default;
    }
}
