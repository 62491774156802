.page-loader{
    .overlay, .content{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #f8f8f856;
    }
    .overlay{
      z-index: 1000;
    }
    .content{
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10002;
      margin-top: 10px;
    }
    .wrapper{
      padding: 30px;
      text-align: center;
      animation: imagePulse 1.4s linear infinite;
    }
    img{
      margin: 20px auto;
      height: 100px;
      width: 100px;
    }
    .wrapper-text{
      margin: 10px 0;
      font-weight: bold;
      color: #333;
    }
    p.wrapper-text{
      font-size: 3rem;
      font-weight: bold;
    }
    .taking-longer{
      margin: 15px 0;
    }
    .taking-longer, .quote{
      opacity: 0;
      &.fade-in{
        animation: fadeIn .8s ease-in-out 0s forwards 1 ;
      }
    }
    .loader{
      position: fixed;
      top: 0;
      z-index: 10000;
      height: 4px;
      width: 400px;
      background-color: #9fbc59;
      animation: loaderWidth 1s linear 0s 1 forwards, slowLoaderWidth 10s linear 1s 1 forwards ;
    }
  }
  
  @keyframes imagePulse {
    0%, 100%{
      opacity: 1;
    }
    50%{
      opacity: .5;
    }
  }
  
  @keyframes fadeIn {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  
  @keyframes loaderWidth {
    0%{
      width: 0%;
    }
    100%{
      width: 90%;
    }
  }
  
  @keyframes slowLoaderWidth {
    0%{
      width: 90%;
    }
    100%{
      width: 99%;
    }
  }
  
  
  .spinny-loader{
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: 5px solid #333;
      position: relative;
      &::after{
        content:"";
        position: absolute;
        height: 30px;
        width: 4px;
        background-color: green;
        top: 50%;
        transform-origin: top center;
        left: 50%;
        margin-left: -2px;
        animation: spinny 2s linear infinite;
      }
  }
  
  @keyframes spinny {
    0%{
      transform:  rotate(0);
    }
    100%{
          transform: rotate(360deg);
    }
  }