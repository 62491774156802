@import "colors";
@import "mixins";

/******* MeraGaon ASP webpage CSS ************/
//// @Author Suman kumar,19th June 2023


.button_language_area_asp {
    text-align: right;
    margin-right: 85px;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 90%;
        background-position-y: 4px;
        border: none;
        padding: 5px 36px 5px 24px;
        background-color: rgba(0, 0, 0, 0.0784313725);
        color: rgba(0, 0, 0, 0.9411764706);
        font-size: 16px;
        font-family: montserrat;
        font-weight: 500;
        border-radius: 16px;
        cursor: pointer;
    }
}

.beta_Text_area_asp {
    text-align: center;
    margin-top: 60px;

    button {
        background-color: #97b52b26;
        color: #88AD49;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        padding: 4px 14px;
        border: none;
        border-radius: 20px;
        cursor: default;
    }
}

.mg_asp_bold_header {
    margin: 30px auto;
    font-size: 44px;
    color: #000000c9;
    text-align: center;
    padding: 4px;
    font-weight: 500;
    width: 60vw;
}

.mg_asp_light_header {
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
    color: #000000b0;
    font-weight: 500;
    padding: 4px 4px;
    margin: 4px auto;
    width: 60vw;
}

.mg_asp_home_btn_area {
    display: flex;
    gap: 30px;
    width: 32%;
    margin: 60px auto 120px auto;
}

.mg_asp_home_btn_ask_question {
    background: #2858c0;
    border-radius: 24px;
    color: white;
    padding: 12px 8px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    border: 1px solid #22448e;
    text-align: center;

    &:hover {
        color: rgb(255, 255, 255);
        background-color: #0a3c64;
    }
}

.mg_asp_home_btn_know_more {
    background: #84ab42;
    border-radius: 30px;
    color: white;
    padding: 12px 8px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    border: 1px solid #5f8a18;

    &:hover {
        color: rgb(255, 255, 255);
        background-color: #506c20;
    }
}

.meraGaon_hero_image_asp {
    margin: 60px auto;
    width: 90vw;

    img {
        width: 100%;
        height: 560px;
        border-radius: 10px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        img {
            height: 420px;
        }
    }

    @media (max-width: 768px) {
        img {
            height: 360px;
        }
    }

    @media (max-width: 500px) {
        img {
            height: 220px;
        }
    }

    @media (max-width: 400px) {
        img {
            height: 190px;
        }
    }
}

.asp_green_section {
    background: linear-gradient(90deg, #D2BA04 0%, #74970B 100%);
    margin: 120px 0 60px 0;
}

.asp_green_number_section {
    h2 {
        padding: 40px 4px 30px 4px;
        width: 70vw;
        margin: auto;
        color: white;
        font-size: 40px;
        text-align: center;
    }

    p {
        width: 65vw;
        margin: auto;
        color: #2e3e06;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        line-height: 1.6;

        span {
            color: white;
            text-decoration: underline;
        }
    }
}

.asp_number_lottie_area {
    margin-top: 24px;
}

.asp_responsive_lottie {
    width: 900px;
    max-width: 900px;
    height: 100px;
}

.asp_green_number_section_map {
    // margin-top: 40px;

    h2 {
        padding: 60px 4px 30px 4px;
        width: 60vw;
        margin: auto;
        color: white;
        font-size: 40px;
        text-align: center;
    }

    p {
        width: 65vw;
        margin: auto;
        color: #2e3e06;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        line-height: 1.6;

        span {
            color: white;
            text-decoration: underline;
        }
    }
}

.asp_green_map_section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: 40px auto;
    // padding-bottom: 60px;
}

.asp_green_map_side {
    img {
        height: 590px;
        width: 510px;
    }
}

.asp_green_number_counter {
    h1 {
        color: white;
        font-size: 48px;
        font-weight: 600;
    }

    h3 {
        font-weight: 500;
        color: #fffed4;
        font-size: 28px;
        padding: 4px 0px;
    }
}

.get_mg_app_green_asp {
    text-align: center;
    margin-top: 8px;

    button {
        border: none;
        background: transparent;
    }

    img {
        width: 220px;
        max-width: 220px;
    }
}

.mg_step-join {
    // top: 20px;
}

.mg_step-join-us {
    padding: 70px 20px 80px 20px;

    h1 {
        // color: #95b247;
        color: white;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;

        p {
            text-align: center;
            font-weight: 500;
            font-family: montserrat;
            font-size: 18px;
            color: #465e07;
            padding: 12px 12px 60px 12px;
        }
    }
}

.asp_card_container {
    display: flex;
    justify-content: space-around;
}

.asp_container_card {
    background: white;
    width: 320px;
    padding: 48px 24px 48px 24px;
    border-radius: 14px;
    border: 1px solid #a7c06666;
}

.asp_container_card_top {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.asp_container_card_icon {
    width: 65px;
    height: 65px;
}

.asp_container_card_icon:hover {
    color: red;
}

.asp_container_card_number {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 80px;
    line-height: 52px;
    align-items: center;
    text-align: center;
    color: #aec673;
    opacity: 0.8;
}

.asp_container_card h2 {
    padding: 20px 0px 10px 0px;
    font-size: 23px;
    font-weight: 500;
    font-family: Montserrat;
    font-style: normal;
}

.asp_container_card_text {
    font-size: 15px;
    line-height: 22px;
    color: #000000c9;
}

.asp_container_card:hover {
    background-color: #030e1494;
    // color: white;
    cursor: pointer;
    transition: all 0.2s ease-out;
    border-radius: 6px;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    color: #aec673;
    border: none;

    .asp_container_card_text {
        opacity: 0.9;
        // color: #516915;
        color: white;
    }
}

@media (max-width: 680px) {
    .asp_card_container {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 40px;
        margin-top: 20px;
    }
}

.asp_masterji_section {
    margin-top: 60px;

    h2 {
        padding: 14px 20px 18px 14px;
        width: 60vw;
        margin: auto;
        color: #95b247;
        ;
        font-size: 42px;
        text-align: center;
    }

    p {
        width: 65vw;
        margin: auto;
        color: #465e07;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        line-height: 1.6;

        span {
            color: white;
            text-decoration: underline;
        }
    }
}

.asp_masterji {
    display: flex;
    align-items: center;
    margin-top: 30px;
    background: #e4f3ec;
    padding: 40px 0;

    img {
        height: 480px;
        width: 84%;
    }
}

// ASP question page
.asp_ask_question_section {
    padding: 1px 0px 50px 0px;
    margin-top: 60px;

    h4 {
        color: #094915;
        text-align: center;
        font-size: 36px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding-bottom: 32px;
        width: 60%;
        margin: auto;
        font-weight: 500;
    }
}

.ask_question_new_form_detail {
    margin: auto;
    width: 55%;
    padding-bottom: 40px;
}

.ask_question_new_form {
    padding: 6px;

    fieldset {
        margin-bottom: 20px;
        border: 1px solid #9fbc5991;
        background-color: rgb(210, 253, 135);
        padding: 10px 16px;
        box-shadow: 0 20px 40px rgb(84, 82, 82);
        border-radius: 4px;
        overflow: hidden;
    }

    legend {
        border: 1px solid #c7d99f;
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        background-color: #88ad49;
        width: auto;
        margin: 0px;
        display: inline-block;
        text-align: center;
        color: white;
    }

    input {
        margin-top: 4px;
        width: 100%;
        padding: 9px 12px;
        border: 1px solid #e1e2eb;
        background-color: #fff;
        color: #102a43;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.3rem;
        box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
        border-radius: 3px;

        @include breakpoint(tablet) {
            font-size: 12px;
        }
    }
}

.ask_question_new_form_list {
    list-style: none;
    margin: 0;
    padding: 6px;

    li {
        padding: 0.4em;

        button {
            background: #88ad49;
            color: white;
            font-weight: 700;
            font-size: 13px;
            border-radius: 5px;
            margin-top: 8px;
            padding: 6px 16px;
            border: none;

            &:hover {
                background: #5d8020;
            }
        }
    }

    label {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 500;
        padding: 5px 5px 5px 4px;

        @include breakpoint(tablet) {
            font-size: 12px;
        }
    }

}

.react-select-container-asp {
    font-size: 13px;
}

.newForm_asp_error {
    font-size: 12px;
    color: red;
    padding: 4px 0px 0px 4px;
}

.newForm_asp_modeSelect_error {
    background: white;
    display: flex;
    gap: 40px;
    align-items: center;
    padding: 1px;
    border: 1px solid rgb(225, 225, 225);
    border-radius: 4px;
}

// responsiveness
@media (min-width: 800px) and (max-width: 1024px) {
    .asp_ask_question_section {
        margin-top: 60px;
        padding: 1px 0px 60px 0px;

        h4 {
            font-size: 30px;
        }
    }

    .button_language_area_asp {
        margin-right: 14px;
    }

    .beta_Text_area_asp {
        margin-top: 28px;

        button {
            font-size: 14px;
            padding: 4px 11px;
        }
    }

    .mg_asp_bold_header {
        margin: 20px auto;
        font-size: 30px;
        padding: 4px 14px;
        width: 90vw;
    }

    .mg_asp_light_header {
        font-size: 18px;
        padding: 4px 16px;
        width: 90vw;
    }

    .mg_asp_home_btn_area {
        width: 40vw;
        margin: 30px auto 60px auto;
    }

    .asp_green_section {
        margin: 60px 0 60px 0;
    }

    .asp_green_number_section_map {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }
    }

    .asp_green_map_section {
        flex-direction: row;
        gap: 60px;
    }

    .asp_green_number_counter {
        text-align: left;

        h1 {
            font-size: 40px;
        }

        h3 {
            font-size: 28px;
        }
    }

    .asp_green_map_side {
        img {
            height: 460px;
            width: 50vw;
        }
    }

    .asp_green_number_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 80vw;
            font-size: 30px;
        }

        p {
            width: 85vw;
            padding: 0 4px;
        }
    }

    .get_mg_app_green_asp {
        text-align: center;
        margin-top: 30px;

        img {
            width: 170px;
            max-width: 170px;
        }
    }

    .asp_responsive_lottie {
        width: 95vw;
        height: 65px;
    }

    .mg_step-join-us {
        padding: 40px 20px 80px 20px;

        h1 {
            font-size: 36px;

            p {
                font-size: 16px;
                padding: 10px 2px;
            }
        }
    }

    .asp_card_container {
        margin-top: 20px;
        gap: 16px;
    }

    .asp_container_card {
        width: 300px;
    }

    .asp_masterji_section {
        margin-top: 60px;

        h2 {
            padding: 14px 4px 16px 4px;
            width: 80vw;
            font-size: 30px;
        }

        p {
            width: 85vw;
            padding: 0 4px;
        }
    }

    .asp_masterji {
        display: flex;
        align-items: center;
        margin-top: 30px;
        background: #e4f3ec;
        padding: 14px 0;
        flex-direction: row;

        img {
            height: 400px;
            width: 60vw;
           // margin-left: -60px;
        }
    }

    .ask_question_new_form {
        legend {

            font-size: 14px;

        }
    }
}

@include breakpoint(tablet) {
    .ask_question_new_form_detail {
        width: 70vw;
    }

    .asp_ask_question_section {
        margin-top: 60px;
        padding: 1px 0px 60px 0px;

        h4 {
            font-size: 28px;
        }
    }

    .button_language_area_asp {
        margin-right: 14px;
    }

    .beta_Text_area_asp {
        margin-top: 40px;

        button {
            font-size: 14px;
            padding: 4px 11px;
        }
    }

    .mg_asp_bold_header {
        margin: 20px auto;
        font-size: 30px;
        padding: 4px 8px;
        width: 100vw;
    }

    .mg_asp_light_header {
        font-size: 18px;
        padding: 4px 16px;
        width: 90vw;
    }

    .mg_asp_home_btn_area {
        width: 50vw;
        margin: 30px auto 60px auto;
    }

    .asp_green_section {
        margin: 60px 0 60px 0;
    }

    .asp_green_number_section_map {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }
    }

    .asp_green_map_section {
        flex-direction: row;
        gap: 40px;
    }

    .asp_green_number_counter {
        text-align: left;

        h1 {
            font-size: 40px;
        }

        h3 {
            font-size: 28px;
        }
    }

    .asp_green_map_side {
        img {
            height: 460px;
            width: 60vw;
        }
    }

    .asp_green_number_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 80vw;
            font-size: 30px;
        }

        p {
            width: 85vw;
            padding: 0 4px;
        }
    }

    .get_mg_app_green_asp {
        text-align: center;
        margin-top: 30px;

        img {
            width: 170px;
            max-width: 170px;
        }
    }

    .asp_responsive_lottie {
        width: 95vw;
        height: 65px;
    }

    .mg_step-join-us {
        padding: 40px 20px 80px 20px;

        h1 {
            font-size: 36px;

            p {
                font-size: 16px;
                padding: 10px 2px;
            }
        }
    }

    .asp_card_container {
        margin-top: 20px;
        gap: 16px;
    }

    .asp_container_card {
        width: 300px;
    }

    .asp_masterji_section {
        margin-top: 60px;

        h2 {
            padding: 14px 4px 16px 4px;
            width: 80vw;
            font-size: 30px;
        }

        p {
            width: 85vw;
            padding: 0 4px;
        }
    }

    .asp_masterji {
        display: flex;
        align-items: center;
        margin-top: 30px;
        background: #e4f3ec;
        padding: 14px 0;
        flex-direction: row;

        img {
            height: 240px;
            width: 100%;
            margin-left: -80px;
        }
    }

    .ask_question_new_form {
        legend {

            font-size: 14px;

        }
    }
}

@media (min-width:448px) and (max-width: 767px) {
    .ask_question_new_form_detail {
        width: 80vw;
    }

    .asp_green_map_side {
        img {
            height: 400px;
            width: 80vw;
        }
    }

    .asp_container_card {
        width: 100%;
    }

    .asp_masterji {
        flex-direction: column;

        img {
            height: 320px;
            width: 90vw;
            margin-left: 0px;
        }
    }
}

@include breakpoint(mobile) {
    .asp_ask_question_section {
        padding: 1px 0px 20px 0px;
        margin-top: 40px;

        h4 {
            font-size: 18px;
            width: 95vw;
            padding: 1px 8px 16px 8px;
        }
    }

    .ask_question_new_form_detail {
        width: 90vw;
        padding-bottom: 10px;
    }
    .beta_Text_area_asp {
        margin-top: 14px;
    }
    .button_language_area_asp {
        margin-right: 14px;
    }

    .mg_asp_bold_header {
        margin: 20px auto;
        font-size: 26px;
        padding: 10px 14px 4px 14px;
        width: 100vw;
    }

    .mg_asp_light_header {
        font-size: 16px;
        padding: 4px 16px;
        width: 100vw;
    }

    .mg_asp_home_btn_area {
        width: 90vw;
        margin: 40px auto 60px auto;
    }

    .asp_green_section {
        margin: 60px 0 60px 0;
    }

    .asp_green_number_section_map {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 90vw;
            font-size: 26px;
        }

        p {
            width: 90vw;
            padding: 0 14px;

            span {
                color: white;
                text-decoration: underline;
            }
        }
    }

    .asp_green_map_section {
        flex-direction: column;
        gap: 60px;
    }

    .asp_green_number_counter {
        text-align: center;

        h1 {
            font-size: 36px;
        }

        h3 {
            font-size: 24px;
        }
    }

    .asp_green_map_side {
        img {
            height: 400px;
            width: 90vw;
        }
    }

    .asp_green_number_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 90vw;
            font-size: 26px;
        }

        p {
            width: 90vw;
            padding: 0 14px;

            span {
                color: white;
                text-decoration: underline;
            }
        }
    }

    .get_mg_app_green_asp {
        text-align: center;
        margin-top: 14px;

        img {
            width: 170px;
            max-width: 170px;
        }
    }

    .asp_responsive_lottie {
        width: 95vw;
        height: 65px;
    }

    .mg_step-join-us {
        padding: 40px 20px 80px 20px;

        h1 {
            font-size: 28px;

            p {
                font-size: 16px;
                padding: 10px 2px;
            }
        }
    }

    .asp_masterji_section {
        margin-top: 60px;

        h2 {
            padding: 4px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }
    }

    .asp_masterji {
        display: flex;
        align-items: center;
        margin-top: 30px;
        background: #e4f3ec;
        padding: 14px 0;
        flex-direction: column;

        img {
            height: 220px;
            width: 96vw;
            margin-left: 0px;
        }
    }

    .ask_question_new_form {
        legend {

            font-size: 14px;

        }
    }

    .asp_container_card {
        width: 100%;
    }
}
.new_asp_video {
    width: 100%;
    height: auto;
    border:4px solid #97ba519c;
    video{
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}
