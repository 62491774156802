@import "reset";
@import "reusable";
@import "header";
@import "buttons";
@import "footer";
@import "colors";
@import "heading";
@import "./page-loader";
@import "commons";
@import "aboutUs";
@import "careers";
@import "protected_login";
@import "candidate_form";
@import "./Candidate/addressAndDocsPage";
@import "./Candidate/candidate-reset";
@import "buyer";

.toaster-container {
    font-size: 16px;
}

@import "./asp.scss";
@import "./newAsp.scss";
@import "./meraGaon.scss";
@import "./Audio/index.scss";
@import "./feed/timeline.scss";
@import "./react-modal.scss";
@import "./legalPage.scss";
@import "villageCard.scss";
@import "./weekly_activity.scss";
@import "./Testimonial.scss";
@import "./accountDelete.scss";
@import "./feed/feedVideo.scss";
@import "./post-text-font.scss";
@import "./addressLink.scss";