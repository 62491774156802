@import "colors";
@import "mixins";

body {
    background-color: #f1f3f4;
}

.headerText {
    margin-bottom: 4vh;
    text-align: center;
    font-size: 18px;
    font-weight: 505;
    color: #000000d4;
}

.account_deletion_form {
    width: 50%;
    margin: auto;
    padding: 5.4rem;

    background-color: #ffffff;
    padding-right: 40px;
    padding-left: 40px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;

    fieldset {
        padding: 4px 16px 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        border: 1px solid #9fbc5991;
    }
    
    legend {
        border: 1px solid #c7d99f;
        text-align: center;
        padding: 8px;
        color: #5d7520;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        background-color: #f9ffe9f2;
        width: auto;
        margin: 0px;
        display: inline-block;
        h5 {
            line-height: 1.3;
        }
    }    

    input {
        margin-top: 4px;
        width: 100%;
        padding: 9px 12px;
        border: 1px solid #e1e2eb;
        background-color: #fff;
        color: #102a43;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.3rem;
        box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
        border-radius: 3px;
    }

    label {
        font-size: 14px;
        line-height: 1.3rem;
        font-weight: 500;
        color: #000000c4;
        padding-left: 2px;
    }

    @media (max-width: 768px) {
        width: 100%;
        // border: 1px solid red;
        padding-right: 15px;
        padding-left: 15px;

        label {
            font-size: 12px;
        }
    }
}

.deleteOtpSendButton {
    padding: 0.4em;

    button {
        background: #aec673;
        color: white;
        font-weight: 700;
        font-size: 13px;
        border-radius: 5px;
        margin-top: 8px;
        padding: 6px 16px;
        border: none;
    }
}

.submitReasonButton {
    padding: 0.4em;
    text-align: center;
    // border: 1px solid red;

    button {
        background: #aec673;
        color: white;
        font-weight: 700;
        font-size: 13px;
        border-radius: 5px;
        margin-top: 8px;
        padding: 6px 16px;
        border: none;
    }
}

.labelClass {
    cursor: pointer;
}

.spanClass {
    height: auto;
    // border: 1px solid red;
    color: #131313;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.errorText {
    font-size: 12px;
    color: red;
    padding: 6px 0px;
}

#otpResendButton {
    color: #b72626ed;
    background-color: white;
    border: none;
    font-size: 12px;
}

.otpResendText {
    color: #0000009c;
    padding-top: 4px;
    font-size: 14px;
    font-weight: 400;
}

#changeNumberButton {
    color: rgba(27, 88, 135, 0.9);
    background-color: white;
    border: none;
    font-size: 12px;
    margin-top: 10px;
    padding: 0;
}

.reasonBox {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 20px;

    input[type="checkbox"] {
        display: none;
    }
    
    input[type="checkbox"] + span:before {
        width: 18px;
        height: 18px;
        border: 1.5px solid gray;
        content: "";
        display: inline-block;
        margin: 0 16px 0 0;
        padding: 0;
        vertical-align: top;
        border-radius: 50%;
    }
    
    input[type="checkbox"]:checked + span:before {
        background: #1460c8;
        border: 1.5px solid #1460c8;
        box-shadow: inset 0px 0px 0px 1px #fff;
        color: #333;
        content: "";
        text-align: center;
        border-radius: 50%;
    }
    
    input[type="checkbox"]:focus + span::before {
        outline: 0;
    }
    
}

.reasonBoxButtons {
    // border:1px solid red;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
        margin: auto;
    }

    padding: 15px 0 5px 0;
}

.continueButton {
    background: #aec673;
    color: white;
    font-weight: 700;
    font-size: 13px;
    border-radius: 5px;
    padding: 6px 16px;
    border: none;
}

#backButton {
    background-color: #7b9ea4;
    color: black;
}

.deleteButton {
    padding: 6px 14px 6px 14px;
    min-height: 30px;
    font-size: 1vw;
    border: none;
    color: white;
    background: #ae1e2c;
    border-radius: 6px;

    i {
        padding-right: 5px;
    }

    @media (max-width: 768px) {
        padding: 6px 18px 6px 18px;
        font-size: 3vw;
    }
}

.confirmationBox {
    padding: 0 100px 0 100px;
}

.modalButtonsBox {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    padding: 25px 0 5px 0;
}

.modalImageNameBox {
    border: 0.1px solid gray;
    height: fit-content;
    width: 80%;
    margin: auto;
    margin-top: 15px;
    padding-bottom: 10px;
}

.modalContentBox {
    margin-bottom: 25px;
    line-height: 22px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;

    h3 {
        font-weight: 600;
        margin-bottom: 20px;
    }
}

.reasonsText {
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 22px;
}

.mobile {
    margin-left: 10%;
    margin-bottom: 3vh;
}
