@import "colors";
@import "mixins";

// .border-container {

//     &:not(:nth-child(1)):not(:nth-child(5)):not(:nth-child(6)):not(:last-child) {
//         border-bottom: 1px solid #eee;
//     }

// }

.main-container {

    // padding-top: 6rem;
    &--heading {
        margin: 0 auto;
        width: 65rem;
        text-align: center;
        font-weight: bold;

        @include breakpoint(laptop) {
            width: 100%;
        }

        @include breakpoint(tablet) {
            width: 100%;
        }

        @include breakpoint(phablet) {
            width: 100%;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    &--text {
        margin: 3rem auto 0 auto;
        width: 100rem;
        text-align: center;
        line-height: 2.8rem;

        @include breakpoint(tablet) {
            width: 100%;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    @include breakpoint(mobile) {
        &--heading {
            margin-top: 2rem;
        }
    }
}

.vision-section {
    padding: 0 5rem;

    @include breakpoint(tablet) {
        padding: 0 7rem;
        text-align: left;
    }

    @include breakpoint(phablet) {
        padding: 0 5rem;
        text-align: left;
    }

    @include breakpoint(mobile) {
        padding: 0;
        text-align: left;
    }
}

.main-heading {
    @include breakpoint(tablet) {
        padding: 0 7rem;
        text-align: left;
    }

    @include breakpoint(phablet) {
        padding: 0;
        text-align: left;
    }

    @include breakpoint(mobile) {
        padding: 0;
        text-align: left;
    }
}

.main-text {
    @include breakpoint(tablet) {
        padding: 0 7rem;
        text-align: left;
    }

    @include breakpoint(phablet) {
        padding: 0;
        text-align: left;
    }

    @include breakpoint(mobile) {
        padding: 0;
        text-align: left;
    }
}