@import "colors";
@import "mixins";

/******* Legal page CSS ************/
//// @Author Suman kumar, 19th july, 2022.
.legal_page_wrapper {
    padding: 10px;
    font-family: none;

    h1 {
        text-align: center;
    }

    h4 {
        font-weight: bold;
        padding: 4px 0;
        font-size: 20px;
    }

    p {
        font-weight: 500;
        padding: 4px 0;
        font-size: 16px;
        color: black;

        i {
            font-size: 17px;
            color: black;
        }
    }

    table {
        border: 1px solid black;
        width: 100%;
        margin-bottom: 18px;

        tr {
            text-align: center;
        }

        th {
            text-align: center;
            font-size: 18px;
        }

        td {
            border: 1px solid black;
            width: 50%;
            font-size: 16px;
        }

    }
}