// video-body css
* {
    margin: 0;
    box-sizing: border-box;
}

.mg_vide_container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: -1;
    background-color: black;
}

.mg_video_logo {
    top: 10px;
    position: absolute;
    margin: 0;
    background-color: transparent;
    align-items: center;
    padding: 10px;
}

.mg_video_feed_page {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    margin: auto;
    z-index: -1;
}

.mg_video_cover_box {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    position: relative;
    margin: auto;
}

.mg_video_box {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    object-fit: cover;
    position: relative;
    margin: auto;
    z-index: -1;
}

.videoLoader {
    width: 20%;
    height: 0px;
    position: absolute;
    top: 46.5%;
    right: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 76px;
    font-weight: bold;
    color: white;
    opacity: 75%;
    margin: auto;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 12%;
}

.mg_video_profile_box {
    padding-left: 20px;
    position: fixed;
    color: white;
    max-width: 315px;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_user {
        display: flex;
        align-items: center;
    }

    &_user_image {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: ghostwhite;

        img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
        }
    }

    &_user-info {
        padding: 0 10px;

        strong {
            display: inline-block;
            margin-right: 7px;
            font-size: 18px;
            font-weight: 520;
        }
    }

    h5 {
        font-size: 16px;
    }

    p {
        margin-top: 5px;
        font-size: 15px;
        line-height: 13px;
    }
}

.mg_action_details {
    width: 400px;
    position: fixed;
    bottom: 7%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 18px;

    .iconCountBox {
        width: 56px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 25px;
        height: 25px;
    }

    i {
        font-size: 22px;
        cursor: pointer;
    }

    p {
        font-size: 18px;
        font-weight: 500;
    }

    @media (max-width: 400px) {
        min-width: 100%;
        max-width: 100%;
    }
}

.mg_action_details_whatsapp {
    padding: 3px 2px 4px 1px;
    border-radius: 50%;
    background-color: white;
    height: 30px;
    width: 30px;

    img {
        height: 24px;
        width: 26px;
        padding-left: 3px;
    }
}

.mg_video_model_box {
    padding: 0 100px 0 100px;
}

.mg_logo_container {

    img {
        @include easing(0.4s, ease, 0s);
        max-width: 150px;
        margin: auto;
        padding-bottom: 20px;
    }
}

#file_videoFeature {
    width: 80vw;
    /* Set the width of the progress bar */
    max-width: 280px;
    /* Set the height of the progress bar */
    appearance: none;
    /* Remove the default styling */
    height: 5px;
    border-radius: 8px;
    /* Set the border radius to create rounded corners */
}

#file_videoFeature::-webkit-progress-bar {
    background-color: #393838;
    /* Set the background color of the progress bar container */
    border-radius: 8px;
    /* Set the border radius to create rounded corners */
}

#file_videoFeature::-webkit-progress-value {
    background-color: white;
    /* Set the color of the progress bar itself */
    border-radius: 8px;
    /* Set the border radius to create rounded corners */
}

#file_campaignVideo {
    width: 80vw;
    /* Set the width of the progress bar */
    max-width: 280px;
    /* Set the height of the progress bar */
    appearance: none;
    /* Remove the default styling */
    height: 5px;
    border-radius: 8px;
    /* Set the border radius to create rounded corners */
}

#file_campaignVideo::-webkit-progress-bar {
    background-color: #393838;
    /* Set the background color of the progress bar container */
    border-radius: 8px;
    /* Set the border radius to create rounded corners */
}

#file_campaignVideo::-webkit-progress-value {
    background-color: rgb(180, 138, 15);
    /* Set the color of the progress bar itself */
    border-radius: 8px;
    /* Set the border radius to create rounded corners */
}