// ASP stand-alone page
.button_language_area_addressForm {
    text-align: right;
    margin-right: 84px;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 90%;
        background-position-y: 4px;
        border: none;
        padding: 5px 36px 5px 24px;
        background-color: rgba(0, 0, 0, 0.0784313725);
        color: rgba(0, 0, 0, 0.9411764706);
        font-size: 16px;
        font-family: montserrat;
        font-weight: 500;
        border-radius: 16px;
        cursor: pointer;
    }
}
.add_address_campaign_background {
    border-radius: 4px;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -4rem;
    @include trans(translateX(-50%));
    background-color: rgba(245, 240, 245, 0.6);
}
.add_address_campaign {
    padding: 1px 0px 10px 0px;
    margin-top: 80px;

    h1 {
        color: #1b1b1b;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        padding: 24px 60px 24px 60px;
        text-decoration: underline;
    }

    h4 {
        color: #1b1b1b;
        text-align: center;
        font-size: 22px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding-bottom: 24px;
    }

    img {
        margin: 8px auto 24px auto;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
}
.add_address_new_form_detail {
    margin: auto;
    width: 55%;
    padding-bottom: 40px;
}
.add_address_new_form {
    padding: 6px;

    fieldset {
        margin-bottom: 20px;
      //  border: 1px solid #9fbc5991;
      //  background-color: rgb(232 255 193 / 26%);
        padding: 10px 16px;
        box-shadow: 0 20px 40px rgba(118, 118, 118, 0.27);
        border-radius: 4px;
        overflow: hidden;
    }

    legend {
        border: 1px solid #c7d99f;
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        // background-color: #88ad49;
        width: auto;
        margin: 0px;
        display: inline-block;
        text-align: center;
        color: white;
        background-color: white;
    }

    input {
        margin-top: 4px;
        width: 100%;
        padding: 9px 12px;
        border: 1px solid #e1e2eb;
        background-color: #fff;
        color: #102a43;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.3rem;
        box-shadow: inset 0 2px 4px 0 rgba(206, 209, 224, 0.2);
        border-radius: 3px;

        @include breakpoint(tablet) {
            font-size: 12px;
        }
    }
}
.add_address_new_form_list {
    list-style: none;
    margin: 0;
    padding: 6px;

    li {
        padding: 0.4em;

        button {
            // background: #88ad49;
            // color: white;
            font-weight: 700;
            font-size: 13px;
            border-radius: 5px;
            margin-top: 8px;
            padding: 6px 16px;
            border: none;

            // &:hover {
            //     background: #5d8020;
            // }
        }
    }

    label {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 500;
        padding: 5px 5px 5px 4px;

        @include breakpoint(tablet) {
            font-size: 12px;
        }
    }

}

// responsiveness
@media (min-width: 800px) and (max-width: 1024px) {
    .add_address_campaign {
        margin-top: 80px;
        padding: 1px 0px 10px 0px;
    }

    .button_language_area_addressForm {
        margin-right: 84px;
    }
    .add_address_new_form {
        legend {

            font-size: 14px;

        }
    }
}

@include breakpoint(tablet) {
    .add_address_campaign {
        margin-top: 65px;
        padding: 1px 0px 10px 0px;

        h1 {
            font-size: 32px;
            padding: 42px 60px 14px 60px;
        }

        h4 {
            font-size: 20px;
            padding: 1px 60px 20px 60px;
        }
    }

    .button_language_area_addressForm {
        margin-right: 18px;
    }
    .add_address_new_form_detail {
        width: 70vw;
    }
    .add_address_new_form {
        legend {

            font-size: 14px;

        }
    }
}

@media (min-width:448px) and (max-width: 767px) {
    .add_address_new_form_detail {
        width: 80vw;
    }
}

@include breakpoint(mobile) {
    .add_address_campaign {
        padding: 1px 0px 20px 0px;
        top: 0px;

        h1 {
            font-size: 28px;
            padding: 32px 18px 10px 18px;
        }

        h4 {
            font-size: 18px;
            padding: 1px 24px 20px 24px;
        }
    }

    .button_language_area_addressForm {
        margin-right: 16px;
    }
    .add_address_new_form_detail {
        width: 90vw;
        padding-bottom: 10px;
    }
    .add_address_new_form {
        legend {

            font-size: 14px;

        }
    }
}