.mg-timeline-post {
    // padding: 30px 20px;
    padding: 20px 0px;
    width: 100%;
    max-width: 767px;
    margin-bottom: 25px;
    font-size: 16px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

    &-header,
    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 16px;
    }

    &-body {
        margin: 20px 0 28px 0;
        padding: 0px 12px;

        &-caption {
            padding: 0 15px 15px 15px;
            color: #337ab7;
        }

        // to style carousel next and prev button
        .imYUvI {

            &>div:nth-child(2),
            &>div:nth-child(3) {
                width: 40px;
                top: 50%;
                height: auto;
                transform: translateY(-50%);

                &:hover {
                    color: #9fbc59;
                }
            }
        }
    }
}

.mg-timeline-post-header {
    &__user {
        display: flex;
        align-items: center;
    }

    &__user-image {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: ghostwhite;

        img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
        }
    }

    &__user-info {
        padding: 0 10px;

        div {
            small {
                display: inline-block;
                margin-right: 7px;
            }
        }
    }
}

.mg-timeline-post-footer {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // border-top: 1px solid #ccc;
    .fa {
        color: #555;
    }

    button {
        background-color: transparent;
        border: none;
    }
}

.mg-post-share-data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;

    div {
        height: 25px;
        width: 25px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        position: relative;

        span {
            position: absolute;
            background: #9fbc59;
            font-size: 14px;
            top: -33%;
            right: -17px;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.mg-timeline-post-body-text {
    padding: 50px 30px;
    text-align: center;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 15px;
}

.mg-timeline-post-body-image-video {
    video {
        max-height: 400px;
        max-width: 100%;
        width: 90vw;
        object-fit: cover;
        cursor: pointer;
    }

    img {
        width: 100%;
        //object-fit: contain;
        object-fit: cover;
        max-height: 400px;
        height: 100%;
        //border-radius: 15px;
        cursor: pointer;
    }

    @include breakpoint(mobile) {
        video {
            max-height: 320px;
        }

        img {
            max-height: 320px;
        }
    }
}

.mg-timeline-post-body-image-video-single {
    video {
        max-height: 450px;
        max-width: 100%;
        width: 90vw;
        object-fit: cover;
        cursor: pointer;
    }

    img {
        width: 100%;
        object-fit: cover;
        max-height: 450px;
        height: 100%;
        cursor: pointer;
    }

    @include breakpoint(mobile) {
        video {
            max-height: 400px;
        }

        img {
            max-height: 400px;
        }
    }
}

.mg-timeline-post-comment-body-text {
    padding: 50px 30px;
    text-align: center;
    height: 300px;
    max-height: 320px;
    overflow-y: scroll;
}

.mg-timeline-post-body-document {
    margin: 0 16px;
    height: 300px;
    max-height: 320px;
    overflow-y: scroll;
}

.mg-timeline-comment-audio {
    width: 100%;
    height: 80px;
    padding: 4px 16px 2px 16px;
}

.mg-timeline-comment-post-body-image-video {
    video {
        max-width: 86%;
        width: 84%;
        max-height: 320px;
        margin-left: 16px;
    }

    img {
        width: 84%;
        margin-left: 16px;
        object-fit: fill;
        max-height: 380px;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }
}

.mg-timeline-audio {
    padding: 90px 40px;
    margin: 0 -15px;
}

.mg-tagged-users {
    display: flex;
    flex-wrap: nowrap;

    //overflow-x: auto;
    .mg-tagged-user {
        margin-right: 15px;
        min-height: 40px;
        min-width: 40px;
        border-radius: 50%;

        img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }
    }
}

.mgtooltip {
    position: relative;
    display: inline-block;

    &:hover {
        color: red;
    }
}

.mgtooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #aec673;
    font-weight: 500;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 100;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.mgtooltip:hover .tooltiptext {
    visibility: visible;
    margin-bottom: 4px;
}

.mg-carousel-dots {
    justify-content: center;
    display: flex;
    gap: 8px;
    margin-top: 10px;

    span {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: #ccc;
        transition: background-color 0.2s linear;
    }

    span.mg-carouse-dot-active {
        background-color: #333;
    }
}

.mg-feed-page {
    background-color: #f6f6f7;

    &-wrapper {
        padding: 110px 0 24px 0;
        width: 500px;
        max-width: 95%;
        margin: auto;
    }
}

.mg-feed-page_openApp_btn {
    margin: 15px 0px;
    text-align: center;

    button {
        border: none;
        background: #88ad49;
        color: white;
        padding: 10px 30px;
        font-size: 14px;
        border-radius: 4px;
        font-weight: 500;
    }
}

.mg-user-type {
    color: green;
    font-family: "Aileron";
}

.mg-share-details {
    display: flex;

    .fa {
        font-size: 25px;
    }

    .mg-share-detail {
        margin-left: 24px;
        position: relative;

        h4 {
            font-size: 14px;
        }
    }

    span {
        position: absolute;
        top: -5px;
        border-radius: 50%;
        color: white;
        height: 26px;
        width: 26px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-weight: bold;
        font-size: 14px;
        left: -56%;
    }

    img {
        height: 24px;
    }
}

///////// Timeline comment & Replies scss
.mg-commented-user-container {
    padding-left: 16px;
    margin-bottom: -12px;
}

.mg-reply-user-container {
    margin-bottom: -16px;
    margin-left: -16px;
    top: 4px;
}

.mg-commented-user {
    display: flex;
    margin-bottom: 25px;
    margin-top: -4px;
    gap: 6px;
}

.mg-commented-user-image-section {
    width: 10%;

    img {
        height: 50px;
        margin-right: 10px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    }
}

.mg-commented-reply-user-image-section {
    width: 10%;

    img {
        height: 50px;
        margin-right: 10px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    }
}

.mg_commented_user_details {
    background-color: #edf8eb87;
    border-radius: 10px;
    padding: 4px 10px;
    max-width: 22em;
    min-width: 16em;

    h4 {
        font-weight: 700;
        padding: 2px;
        text-transform: capitalize;
        font-size: 18px;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;

        padding: 2px;
        word-wrap: break-word;
    }
}

.mg_commented_reply_user_details {
    background-color: #f5fbf4;
    border-radius: 10px;
    padding: 4px 10px;
    max-width: 22em;
    min-width: 16em;

    h4 {
        font-weight: 700;
        padding: 2px;
        text-transform: capitalize;
        font-size: 18px;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        padding: 2px;
        word-wrap: break-word;
    }
}

.mg_comment_footer_button {
    border: none;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
}

.mg_comment_footer_like_button {
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
}

.mg_comment_footer {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-left: -14px;

    p {
        font-size: 14px;
    }
}

.mg_reply_footer {
    top: -24px;
    margin-left: 58px;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
}

@media (min-width: 320px) and (max-width: 768px) {
    .mg_commented_user_details {
        max-width: 14em;
        min-width: 14em;

        h4 {
            font-size: 14px;
        }

        p {
            font-size: 11px;
        }
    }

    .mg-commented-user-image-section {
        width: 16%;
    }

    .mg-commented-reply-user-image-section {
        width: 18%;
    }

    .mg-reply-user-container {
        margin-left: -26px;
    }

    .mg_commented_reply_user_details {
        max-width: 14em;
        min-width: 12em;

        h4 {
            font-size: 14px;
        }

        p {
            font-size: 11px;
        }
    }

    .mg_comment_footer_button {
        font-size: 11px;
    }

    .mg_comment_footer {
        gap: 2px;
        margin-left: -2px;

        p {
            font-size: 11px;
        }
    }

    .mg_reply_footer {
        margin-left: 70px;
        gap: 6px;
    }

    .mg-share-details {
        .mg-share-detail {
            margin-left: 22px;

            h4 {
                font-size: 11px;
            }
        }
    }
}

.mg_comment_footer_button_like {
    background: #8fbc43;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 16px;
}

.mg-reply-user {
    display: flex;
    margin-bottom: 25px;
    margin-right: 40px;
    margin-top: 2px;
}

.mg_comment_reply {
    margin-left: 70px;
    top: -24px;
}

.mg_comment_footer_button_like_icon {
    margin-right: 130px;
    top: -10px;
}

// .mg_reply_footer {
//     top: -24px;
//     margin-left: 58px;
//     font-size: 14px;
//     display: flex;
//     gap: 10px;
//     align-items: center;
// }
.mg-liked-user {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    margin-bottom: 18px;

    img {
        height: 50px;
        margin-right: 10px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    }
}

.mg-share-user {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    margin-bottom: 12px;

    img {
        height: 50px;
        margin-right: 10px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    }

    p {
        font-size: 12px;
        padding: 5px 0px 0px 0px;
        opacity: 0.76;
        font-weight: 300;
    }

    h4 {
        font-size: 20px;
    }
}

.mg-share-list-details {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mg-share-list {
    img {
        height: 20px;
        width: 20px;
    }
}

.mg_comment_button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    margin-left: 4px;
}

.mg_post_footer_comment_area {
    margin: 24px 8px 0 4px;
    display: flex;
    justify-content: space-between;
}

.mg_post_view_count {
    border: none;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    cursor: default;
    margin-right: 4px;
    color: #454545;

    i {
        font-size: 18px;
        padding-right: 4px;
    }
}

.poll_post_section {
    border-radius: 6px;
    border: 1px solid #d1cccc;
    padding: 4px 10px;
    margin: 4px 10px;

    h4 {
        text-align: left;
        padding: 4px 4px 10px 4px;
        font-size: 18px;
    }
}

.poll_post_option_section {
    padding: 8px 0px;
    cursor: default;
}

.poll_option_before_click {
    background-color: #f3f3f3a6;
    border: 1px solid #cbd1be;
    border-radius: 6px;
    padding: 8px 16px;
    font-size: 16px;
    text-align: center;
    color: #000000d9;
    width: 60%;
    // &:hover {
    //     color: rgb(255, 255, 255);
    //     background-color: #91ab55;
    // }
}

.poll_post_section_aftervote {
    padding: 8px 0px;
    cursor: default;
}

.poll_options_aftervote {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.poll_option {
    background-color: #f3f3f3a6;
    border: 1px solid #cbd1be;
    border-radius: 8px;
    padding: 8px 16px;
    //margin-bottom: 14px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000000d9;
    width: 60%;
}

.poll_icon {
    height: 26px;
    width: 26px;
    border: 2px solid #91ab55;
    border-radius: 50%;
    font-size: 13px;
    line-height: 24px;
    pointer-events: none;
    text-align: center;

    &-tick {
        color: white;
        background-color: #9fbc59;
    }
}

.poll_post_option_bottom_section {
    display: flex;
    justify-content: space-between;
    padding: 6px 4px;
    align-items: center;

    p {
        font-size: 16px;
        color: #343a40e0;

        button {
            border: none;
            background-color: transparent;
        }
    }
}

.mg-poll-vote-user {
    display: flex;
    text-decoration: none;
    padding-bottom: 20px;
    align-items: center;
    gap: 4px;

    img {
        height: 50px;
        margin-right: 10px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.mg-poll-vote-user-details {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    a {
        color: #3d3d3d;
        cursor: pointer;

        &:hover {
            color: #337ab7;
        }
    }
}

.poll_option_vote-details {
    display: flex;
    gap: 14px;
    align-items: center;

    h4 {
        font-size: 15px;
        padding: 2px 0px 0px 0px;
    }
}

.poll_option_voter-details-btn {
    border: none;
    background-color: transparent;
    color: #4d5257;
}

.mg_feed_location_Ui {
    background: #dce2da;
    border: none;
    padding: 6px 14px 6px 8px;
    border-radius: 14px;
    font-size: 12px;
    cursor: default;
}

// classified css
.mg-timeline-classified-post {
    padding: 10px 10px 16px 10px;
    width: 100%;
    max-width: 767px;
    margin-bottom: 25px;
    font-size: 16px;
    border-radius: 10px;
    background-color: #f0f1c4;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    // border: 2px solid #ede58b;

    &-header,
    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 16px;
    }

    &-body {
        margin: 20px 0 28px 0;
        padding: 0px 12px;

        &-caption {
            padding: 0 15px 15px 15px;
            color: #337ab7;
        }

        // to style carousel next and prev button
        .imYUvI {

            &>div:nth-child(2),
            &>div:nth-child(3) {
                width: 40px;
                top: 50%;
                height: auto;
                transform: translateY(-50%);

                &:hover {
                    color: #9fbc59;
                }
            }
        }
    }
}

.mg_classified-post-body-image-video {
    padding-top: 0px;

    video {
        max-height: 400px;
        max-width: 100%;
    }

    img {
        width: 100%;
        object-fit: cover;
        max-height: 300px;
        border-radius: 10px;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-slider .slick-dots li {
        margin: 0 -4px !important;
    }
}

.classified_detail_section {
    padding: 0px 4px;

    h4 {
        padding: 6px 0 4px 4px;
        // font-size: 24px;
        // font-weight: 600;
        font-size: 18px;
        font-weight: 500;
        color: #4d4213;
    }

    p {
        padding: 4px;
        font-size: 18px;
    }

    h3 {
        color: #000000a8;
        font-size: 20px;
        padding: 0px 0px 6px 0;

    }

    hr {
        margin-top: 4px;
        margin-bottom: 20px;
        border: 0;
        border-top: 4px solid #e3e3e3;
    }
}

.classified_detail_price_section {
    display: flex;
    align-items: center;
    gap: 10px;
    //padding: 8px 4px 4px 4px;
    padding: 2px 4px 12px 4px;
}


@media (min-width: 320px) and (max-width: 768px) {
    .mg_classified-post-body-image-video {
        img {
            width: 100%;
            object-fit: cover;
            // max-height: 200px;
            height: auto;
        }
    }
}

.mg-classified-price-btn {
    border: none;
    background: #f2f26f;
    color: #228f49;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 5px;
    margin-left: 4px;
}