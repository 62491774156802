@import "colors";
@import "mixins";

/******* MeraGaon webpage CSS ************/
//// @Author Suman kumar, 24th Dec, 2021 mdified on 2nd march 2023


.button_language_area {
    text-align: right;
    margin-right: 85px;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 90%;
        background-position-y: 4px;
        border: none;
        padding: 5px 36px 5px 24px;
        background-color: rgba(0, 0, 0, 0.0784313725);
        color: rgba(0, 0, 0, 0.9411764706);
        font-size: 16px;
        font-family: montserrat;
        font-weight: 500;
        border-radius: 16px;
        cursor: pointer;
    }
}

.beta_Text_area {
    text-align: center;
    margin-top: 40px;

    button {
        background-color: #97b52b26;
        color: #88AD49;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        padding: 4px 14px;
        border: none;
        border-radius: 20px;
        cursor: default;
    }
}

.mg_bold_header {
    margin: 30px auto;
    font-size: 48px;
    color: #000000c9;
    text-align: center;
    padding: 4px;
    font-weight: 500;
    width: 50vw;
}
.mg_playstoreCount_header {
    font-weight: 500;
    color: #6b9a1c;
    font-size: 26px;
    padding-bottom: 16px;
    text-align: center;

}
.mg_light_header {
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
    color: #000000b0;
    font-weight: 500;
    padding: 2px 4px;
    margin: 4px auto;
    width: 50vw;
}

.get_mg_app_link_home {
    text-align: center;
    position: relative;
    width: 380px;
    margin: 60px auto;


    input {
        height: 48px;
        margin: 0px;
        width: 100%;
        outline: none;
        background: #f0f3db;
        border: 1px solid #0000001a;
        border-radius: 8px;
        padding: 8px 8px 8px 14px;
        font-size: 14px;
    }

    button {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
        height: 48px;
        cursor: pointer;
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
        background: #88AD49;
        border-radius: 0px 8px 8px 0px;
        border: none;
        font-size: 16px;
        color: white;
        font-weight: 500;
        padding: 8px 16px;

        &:hover {
            background-color: #688832;

        }
    }

    p {

        text-align: left;
        margin: auto;
        padding: 4px 140px 4px 4px;

        a {
            text-decoration: underline;
        }
    }
}

.meraGaon_hero_image {
    margin: 60px auto;
    width: 90vw;

    img {
        width: 100%;
        height: 650px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        img {
            height: 480px;
        }
    }

    @media (max-width: 768px) {
        img {
            height: 380px;
        }
    }

    @media (max-width: 500px) {
        img {
            height: 220px;
        }
    }

    @media (max-width: 400px) {
        img {
            height: 220px;
        }
    }
}

.mg_green_section {
    background: #002D13;
    padding-bottom: 60px;
}

.meraGaon_group_image_section {
    h2 {
        padding: 60px 4px 30px 4px;
        width: 70vw;
        margin: auto;
        color: white;
        font-size: 40px;
        text-align: center;
    }

    p {
        width: 65vw;
        margin: auto;
        color: #ffffffad;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        line-height: 1.5;

        span {
            color: white;
            text-decoration: underline;
        }
    }

    img {
        width: 75vw;
        margin: 90px auto;
    }
}

.meraGaon_screenshot_section {
    h2 {
        padding: 4px 4px 30px 4px;
        width: 70vw;
        margin: auto;
        color: white;
        font-size: 40px;
        text-align: center;
    }

    p {
        width: 62vw;
        margin: auto;
        color: rgba(255, 255, 255, 0.6784313725);
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        line-height: 1.5;

        span {
            color: white;
            text-decoration: underline;
        }
    }
}

.meraGaon_screenshot_image_section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 80vw;
    margin: 48px auto;
    gap: 40px 150px;

    div {
        width: 100%;

        img {
            height: 280px;
            width: 100%;
        }

        h6 {
            text-align: left;
            font-size: 16px;
            color: white;
            margin: 4px 8px 4px 4px;
            line-height: 1.3;
        }
    }

}

.App_get_wrapper {
    margin-top: 140px;
    background: #f7f6f6;
    margin-bottom: -60px;
}

// mg sikka, mg dost and jigsaw css
.mg_sikka_section {
   // background: #BEFFDA;
    // padding-bottom: 60px;
    padding-bottom: 0px;
}

.meraGaon_sikkap_image_section {
    h2 {
        padding: 60px 4px 30px 4px;
        width: 70vw;
        margin: auto;
        color: #184F3A;
        font-size: 40px;
        text-align: center;
    }

    p {
        width: 75vw;
        margin: auto;
        color: #2F5840;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        line-height: 1.5;

        span {
            color: white;
            text-decoration: underline;
        }
    }

    img {
        width: 100vw;
        margin: 90px auto;
    }
}

.mg_dost_section {
    margin-top: -30px;
    background: linear-gradient(to bottom, #FEE7FF, #FFDA58, #E0A600);
    // background: linear-gradient(45deg, #FEE7FF, #FFDA58, #E0A600);

    h2 {
        padding: 80px 20px 18px 14px;
        width: 60vw;
        margin: auto;
        color: #24654C;
        font-size: 42px;
        text-align: center;
    }

    p {
        width: 80%;
        margin: auto;
        color: #465e07;
        font-size: 20px;
        text-align: center;
        font-weight: 500;
        line-height: 1.6;
    }
}

.asp_mdDost_section_div {
    display: flex;
    align-items: center;
    padding: 40px 0;
    justify-content: space-evenly;
}

.asp_mdDost_pSection {
    width: 50%;
    margin-top: 14px;
    margin-top: -40px;
}

.mg_jigsaw_section {
    margin-top: 0px;
    background: #002D13;
    padding-bottom: 30px;

    h2 {
        padding: 80px 20px 18px 14px;
        width: 60vw;
        margin: auto;
        color: white;
        font-size: 42px;
        text-align: center;
    }

    p {
        width: 80%;
        margin: auto;
        color: white;
        font-size: 22px;
        text-align: center;
        font-weight: 500;
        line-height: 1.6;
    }
}

// media query for webpage
@include breakpoint(largeDesktop) {}

@media (min-width: 1024px) and (max-width: 1280px) {
    .beta_Text_area {
        margin-top: 22px;

        button {
            font-size: 14px;
            padding: 4px 11px;
        }
    }

    .mg_bold_header {
        margin: 20px auto;
        font-size: 36px;
        padding: 4px 8px;
        width: 90vw;
    }
    .mg_playstoreCount_header {
        font-size: 24px;
        padding-bottom: 10px;
    }
    .mg_light_header {
        font-size: 20px;
        padding: 4px 16px;
        width: 75vw;
    }

    .meraGaon_hero_image {
        width: 85vw;
        margin: 0px auto 60px auto;

        img {
            width: 100%;
            height: 380px;
        }
    }

    .mg_green_section {
        padding-bottom: 24px;
    }

    .meraGaon_group_image_section {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 80vw;
            font-size: 36px;
        }

        p {
            width: 80vw;
            padding: 0 14px;
        }

        img {
            width: 90vw;
            margin: 40px auto;
        }
    }

    .meraGaon_screenshot_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 80vw;
            font-size: 36px;
        }

        p {
            width: 85vw;
            padding: 0 4px;
        }
    }

    .meraGaon_screenshot_image_section {
        grid-template-columns: 1fr 1fr;
        gap: 40px 80px;

        div {
            width: 100%;

            img {
                height: 360px;
                width: 100%;
            }
        }
    }
}

@media (min-width: 901px) and (max-width: 1024px) {
    .button_language_area {
        margin-right: 14px;
    }

    .beta_Text_area {
        margin-top: 28px;

        button {
            font-size: 14px;
            padding: 4px 11px;
        }
    }

    .mg_bold_header {
        margin: 20px auto;
        font-size: 30px;
        padding: 4px 8px;
        width: 100vw;
    }
    .mg_playstoreCount_header {
        font-size: 24px;
        padding-bottom: 10px;
    }
    .mg_light_header {
        font-size: 18px;
        padding: 4px 16px;
        width: 90vw;
    }

    .get_mg_app_link_home {
        margin: 26px auto 44px auto;
        width: 340px;

        input {
            padding: 12px 6px 12px 12px;
            font-size: 12px;
        }

        button {
            font-size: 13px;
            font-weight: 500;
            padding: 12px 16px;
        }

        p {
            padding: 4px 90px 4px 4px;
            font-size: 10px;
        }
    }

    .meraGaon_hero_image {
        width: 85vw;
        margin: 40px auto;

        img {
            width: 100%;
            height: 340px;
        }
    }

    .mg_green_section {
        padding-bottom: 24px;
    }

    .meraGaon_group_image_section {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }

        img {
            width: 90vw;
            margin: 40px auto;
        }
    }

    .meraGaon_screenshot_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 80vw;
            font-size: 30px;
        }

        p {
            width: 85vw;
            padding: 0 4px;
        }
    }

    .meraGaon_screenshot_image_section {
        grid-template-columns: 1fr 1fr;
        gap: 40px 80px;

        div {
            width: 100%;

            img {
                height: 360px;
                width: 100%;
            }

        }
    }

    .App_get_wrapper {
        margin-top: 140px;
        background: #f7f6f6;
    }
    .asp_mdDost_pSection{
        margin-top: 14px;
    }

}

@include breakpoint(tablet) {
    .button_language_area {
        margin-right: 14px;
    }

    .beta_Text_area {
        margin-top: 28px;

        button {
            font-size: 14px;
            padding: 4px 11px;
        }
    }

    .mg_bold_header {
        margin: 20px auto;
        font-size: 30px;
        padding: 4px 8px;
        width: 100vw;
    }
    .mg_playstoreCount_header {
        font-size: 24px;
        padding-bottom: 10px;
    
    }
    .mg_light_header {
        font-size: 18px;
        padding: 4px 16px;
        width: 90vw;
    }

    .get_mg_app_link_home {
        margin: 26px auto 44px auto;
        width: 340px;

        input {
            padding: 12px 6px 12px 12px;
            font-size: 12px;
        }

        button {
            font-size: 12px;
            font-weight: 500;
            padding: 12px 16px;
        }

        p {
            padding: 4px 90px 4px 4px;
            font-size: 10px;
        }
    }

    .meraGaon_hero_image {
        width: 85vw;
        margin: 40px auto;

        img {
            width: 100%;
            height: 340px;
        }
    }

    .mg_green_section {
        padding-bottom: 24px;
    }

    .meraGaon_group_image_section {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }

        img {
            width: 90vw;
            margin: 40px auto;
        }
    }

    .meraGaon_screenshot_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 80vw;
            font-size: 30px;
        }

        p {
            width: 85vw;
            padding: 0 4px;
        }
    }

    .meraGaon_screenshot_image_section {
        grid-template-columns: 1fr 1fr;
        gap: 40px 80px;

        div {
            width: 100%;

            img {
                height: 300px;
                width: 100%;
            }

        }
    }

    .App_get_wrapper {
        margin-top: 140px;
        background: #f7f6f6;
    }

    .meraGaon_sikkap_image_section {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }

        img {
            margin: 40px auto;
        }
    }

    .mg_dost_section {
        margin-top: 16px;

        h2 {
            padding: 40px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 60vw;
            padding: 0 10px 0 24px;
        }
    }

    .asp_mdDost_pSection {
        width: 60vw;
        margin-top: 14px;
    }

    .mg_jigsaw_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 90vw;
            font-size: 30px;
        }

        p {
            width: 60vw;
            padding: 0 10px 0 24px;
        }

    }
}

// @media (max-width: 400px) {}

@include breakpoint(phablet) {
    .App_get_wrapper {
        margin-top: 150px;
        background: #f7f6f6;
        margin-bottom: -100px;
    }
    .asp_mdDost_section_div {
        flex-direction: column;
        padding: 14px 0 40px 0;
        grid-gap: 30px;
        gap: 30px;
        align-items: center;
    }
    .asp_mdDost_pSection{
        width: 95%;
    }
    .mg_dost_section {
        margin-top: -6px;
        p{
            width: 95%;  
        }
    }
    .mg_jigsaw_section {
        p{
            width: 95%;  
        }
    }
}

@include breakpoint(mobile) {
    .button_language_area {
        margin-right: 14px;
    }

    .beta_Text_area button {
        font-size: 14px;
        padding: 4px 11px;
    }

    .mg_bold_header {
        margin: 20px auto;
        font-size: 26px;
        padding: 4px 8px;
        width: 100vw;
    }
    .mg_playstoreCount_header {
        font-size: 19px;
        padding-bottom: 10px;
    }
    .mg_light_header {
        font-size: 16px;
        padding: 4px 16px;
        width: 100vw;
    }

    .get_mg_app_link_home {
        margin: 22px auto 48px auto;
        width: 300px;

        input {
            padding: 13px 6px 12px 6px;
            font-size: 12px;
        }

        button {
            font-size: 12px;
            font-weight: 500;
            padding: 12px 16px;
        }

        p {
            padding: 4px 100px 4px 4px;
            font-size: 8px;
        }
    }

    .meraGaon_hero_image {
        margin: 40px auto;

        img {
            width: 100%;
            height: 200px;
        }
    }

    .mg_green_section {
        padding-bottom: 24px;
    }

    .meraGaon_group_image_section {
        h2 {
            padding: 60px 4px 16px 4px;
            width: 90vw;
            font-size: 26px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }

        img {
            width: 90vw;
            margin: 40px auto;
        }
    }

    .meraGaon_screenshot_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 90vw;
            font-size: 26px;
        }

        p {
            width: 92vw;
            padding: 0 4px;
        }
    }

    .meraGaon_screenshot_image_section {
        grid-template-columns: 1fr;
        gap: 30px 150px;
    }

    .App_get_wrapper {
        margin-top: 200px;
    }

    .meraGaon_sikkap_image_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 90vw;
            font-size: 26px;
        }

        p {
            width: 90vw;
            padding: 0 14px;
        }

        img {
            margin: 40px auto;
        }
    }

    .mg_dost_section {
        margin-top: -10px;

        h2 {
            padding: 40px 4px 16px 4px;
            width: 90vw;
            font-size: 26px;
        }

        p {
            width: 92vw;
            padding: 0 10px;
        }
    }

    .asp_mdDost_pSection {
        width: 90vw;
        margin: auto;

        p {
            font-size: 16px;
        }
    }

    .asp_mdDost_section_div {
        flex-direction: column;
        padding: 14px 0 40px 0;
        gap: 30px;
        align-items: center;
    }

    .mg_jigsaw_section {
        h2 {
            padding: 40px 4px 16px 4px;
            width: 90vw;
            font-size: 26px;
        }

        p {
            width: 92vw;
            padding: 0 10px;
            font-size: 16px;
        }

    }

}

//old webpage css
//hero section /////
.meraGaon_hero {
    margin-top: 90px;

    h1 {
        // position: absolute;
        width: 100%;
        color: #9fbc59;
        justify-content: space-around;
        font-size: 48px;
        font-family: montserrat;
        font-weight: bold;
        padding: 16px;
        text-align: center;
    }

    p {
        //  position: absolute;
        display: flex;
        width: 100%;
        color: #5a5448;
        justify-content: space-around;
        font-size: 28px;
        font-family: montserrat;
        font-weight: 500;
        top: 74px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        h1 {
            font-size: 38px;
            padding: 26px 26px 4px 26px;
        }

        p {
            font-size: 24px;
            padding: 4px 8px;
            top: 68px;
        }
    }

    @include breakpoint(tablet) {
        margin-top: 72px;

        h1 {
            font-size: 32px;
            padding: 26px 26px 4px 26px;
        }

        p {
            font-size: 22px;
            padding: 4px 8px;
            top: 60px;
        }
    }

    @media (max-width: 500px) {
        margin-top: 76px;

        h1 {
            font-size: 28px;
            padding: 16px;
        }

        p {
            font-size: 14px;
            padding: 2px 30px 4px 30px;
            top: 50px;
        }
    }

    @media (max-width: 400px) {
        margin-top: 76px;

        p {
            font-size: 14px;
            padding: 2px 60px 4px 60px;
            top: 54px;
            text-align: center;
            font-weight: 500;
        }
    }
}

.search_village_buton_area {
    top: 140px;
    margin-right: 500px;

    @media (min-width: 1024px) and (max-width: 1280px) {
        margin-right: 400px;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
        margin-right: 400px;
    }

    @media (max-width: 768px) {
        margin-right: 180px;
        top: 110px;
    }

    @media (max-width: 500px) {
        margin-right: 54px;
        top: 72px;
    }

    @media (max-width: 400px) {
        margin-right: 28px;
        top: 74px;
    }
}

/*****use MG  page carousal modified css of carousal package*******/
.use-mg-page-slider {
    max-width: 50%;

    .carousel.carousel-slider .control-arrow {
        opacity: 1 !important;
    }

    .carousel.carousel-slider .control-arrow:hover {
        background: rgba(0, 0, 0, 0) !important;
    }

    @include breakpoint(tablet) {
        max-width: 100%;
    }

    @media (min-width: 900px) and (max-width: 1200px) {
        max-width: 80%;
    }
}

/********use meragaon  section**********/

.usemeragaon-section {
    padding: 40px 0px 0px 0px;

    h1 {
        color: black;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 10px 10px 2px 10px;
    }
}

@include breakpoint(tablet) {
    .usemeragaon-section>h1 {
        font-size: 28px;
        padding: 32px 32px 4px 32px;
    }

    .usemeragaon-section {
        padding: 5px 0px 0px 0px;
    }

    .buton_area {
        margin-right: 160px;
    }
}

@include breakpoint(phablet) {
    .usemeragaon-section {
        padding: 14px 10px 0px 10px;
    }
}

@include breakpoint(mobile) {
    .usemeragaon-section {
        padding: 24px 10px 0px 10px;
    }

    .usemeragaon-section>h1 {
        font-size: 26px;
        padding: 10px 10px 6px 10px;
    }
}

.usemeragaon-section-details {
    padding: 20px 96px 60px 96px;
    display: flex;
    align-items: center;

    @media (min-width: 900px) and (max-width: 1200px) {
        flex-direction: column;
        padding: 8px 0 64px 0;
        flex-wrap: wrap;
    }

    @include breakpoint(tablet) {
        padding: 0 55px 48px 36px;
        flex-wrap: wrap;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
        padding: 14px 0 40px 0;
        flex-wrap: wrap;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 40px 0;
    }

    &-info {
        text-align: left;
        // width: 50%;
        padding-right: 64px;
        float: left;

        &--text {
            margin-top: 4px;
            font-size: 22px;
            color: #333333;
            font-weight: 400;
            line-height: 1.5;
        }

        @media (min-width: 900px) and (max-width: 1280px) {
            .usemeragaon-section-details-info--text {
                font-size: 22px !important;
            }

            width: 80%;
            clear: both;
            padding-right: 0px;
            padding-bottom: 20px;
        }

        @include breakpoint(tablet) {
            .usemeragaon-section-details-info--text {
                font-size: 18px !important;
            }

            width: 100%;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 0px;
            line-height: 26px;
            padding-bottom: 20px;
        }

        @include breakpoint(mobile) {
            .usemeragaon-section-details-info--text {
                font-size: 18px;
            }

            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -24px;
        }
    }
}

/////// use MG business
.useBusinessmeragaon-section {
    padding: 40px 0px 0px 0px;

    h1 {
        color: black;
        text-align: center;
        font-weight: 500;
        font-size: 38px;
        font-family: montserrat;
        font-style: normal;
        opacity: 0.8;
        padding: 20px 10px 2px 10px;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {
    .useBusinessmeragaon-section {
        padding: 4px;

        h1 {
            font-size: 30px;
        }
    }
}

@include breakpoint(tablet) {
    .useBusinessmeragaon-section {
        padding: 4px;
    }

    .useBusinessmeragaon-section>h1 {
        font-size: 26px;
        padding: 24px 32px 4px 32px;
    }

    .useBusinessmeragaon-section {
        padding: 10px 0px 0px 0px;
    }
}

@media (min-width: 448px) and (max-width: 700px) {
    .useBusinessmeragaon-section {
        padding: 24px 12px;
    }

    .useBusinessmeragaon-section>h1 {
        font-size: 26px;
        padding: 14px;

    }
}

@media (min-width: 448px) and (max-width: 700px) {
    .useBusinessmeragaon-section {
        padding: 24px 12px;
    }
}

@include breakpoint(mobile) {
    .useBusinessmeragaon-section {
        padding: 24px 12px;
    }

    .useBusinessmeragaon-section>h1 {
        font-size: 26px;
        padding: 14px;
    }
}

.useBusinessmeragaon-section-details {
    padding: 60px 96px 60px 96px;
    display: flex;
    align-items: center;

    @media (min-width: 900px) and (max-width: 1200px) {
        flex-direction: column;
        padding: 8px 0 64px 0;
        flex-wrap: wrap;
    }

    @include breakpoint(tablet) {
        padding: 0 50px 48px 50px;
        flex-wrap: wrap;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
        padding: 1px 0 40px 0;
        flex-wrap: wrap;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        padding: 0 0 40px 0;
    }

    &-info {
        text-align: left;
        width: 50%;
        padding-right: 64px;
        float: left;

        &--text {
            margin-top: 4px;
            font-size: 22px;
            color: #333;
            font-weight: 400;
            line-height: 1.5;
        }

        &--text2 {
            margin-top: 4px;
            font-size: 22px;
            color: #333;
            font-weight: 500;
            line-height: 1.5;
        }

        @media (min-width: 769px) and (max-width: 1200px) {
            .useBusinessmeragaon-section-details-info--text {
                font-size: 20px !important;
                padding: 0px 50px 24px 50px;
            }

            width: 100%;
            text-align: center;
            padding-right: 10px;
        }

        @media(max-width: 768px) {
            &--text {
                font-size: 18px !important;
            }

            &--text2 {
                font-size: 18px !important;
            }

            width: 100%;
            clear: both;
            text-align: center;
            font-size: 16px;
            padding-right: 30px;
            line-height: 26px;
        }

        @include breakpoint(mobile) {
            &--text {
                font-size: 18px;
            }

            &--text2 {
                font-size: 18px;
            }

            width: 100%;
            padding: 14px;
            clear: both;
            text-align: center;
            font-size: 18px;
            top: -10px;
        }
    }
}

// mg-event
.mg-event {
    padding: 0px 0px 0px 0px;
}

.mg-event-us {
    background-color: #f5f0f599;
    padding: 40px 60px 80px 60px;

    h1 {
        text-align: center;
        font-weight: 500;
        font-size: 38px;
        font-family: montserrat;
        font-style: normal;

        p {
            text-align: center;
            font-weight: 300;
            font-family: montserrat;
            font-size: 20px;
            color: white;
            padding: 12px;
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .mg-event {
        padding: 4px 0px 20px 0px;
    }

    .mg-event-us {
        padding: 40px 60px 50px 60px;
    }
}

@media (max-width: 768px) {
    .mg-event-us {
        padding: 40px 40px 20px 40px;
    }

    .mg-event-us>h1 {
        font-size: 28px;

        p {
            font-size: 16px;
        }
    }

    .mg-event {
        padding: 0px;
    }
}

@media (max-width: 500px) {
    .mg-event-us>h1 {
        font-size: 28px;

        p {
            font-size: 14px;
            padding: 10px 2px;
        }
    }

    .mg-event {
        padding: 0px;
    }
}

@media (max-width: 400px) {
    .mg-event {
        padding: 0px;
    }
}

.event_card_container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 40px;
}

.event_container_card {
    background: white;
    cursor: default;
    width: 360px;
    margin: 10px;
    padding: 16px 20px 20px 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.event_container_card h2 {
    padding: 24px 0px 10px 0px;
    font-size: 23px;
    font-weight: 500;
    font-family: Montserrat;
    font-style: normal;
    text-align: center;
}

.event_container_card_text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 0px 14px;
}

.event_container_card:hover {
    //background-color: rgb(62, 62, 62);
    background-color: #a2bd5e;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    .container_card_text {
        opacity: 0.9;
    }
}

@media (max-width: 768px) {
    .event_card_container {
        padding-top: 20px;
    }
}

@media (max-width: 500px) {
    .event_card_container {
        flex-wrap: wrap;
    }

    .event_card_container {
        padding-top: 10px;
    }
}

// Get MG App
.get_mg_app_section {
    background-color: #7f6127;
    padding: 40px 20px;

    h4 {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        color: white;
    }

    @include breakpoint(tablet) {
        h4 {
            font-size: 26px;
        }
    }

    @include breakpoint(phablet) {
        h4 {
            font-size: 24px;
        }
    }

    @include breakpoint(mobile) {
        padding: 40px 20px;

        h4 {
            font-size: 20px;
        }
    }
}

.get_mg_app {
    text-align: center;
    margin-right: 22px;

    button {
        background: transparent;
        padding: 8px 12px 2px 12px;
        border: none;
    }

    img {
        width: 140px;
        max-width: 150px;
        display: inline-block;
    }

    @include breakpoint(tablet) {}

    @include breakpoint(phablet) {}

    @include breakpoint(mobile) {
        margin-right: 2px;

        button {
            padding: 20px 12px 2px 12px;
        }
    }
}

.get_mg_app_input_section {
    text-align: center;
    padding: 18px 12px 12px 12px;

    input {
        padding: 12px;
        width: 190px;
        border: none;
        font-size: 12px;
        border-radius: 10px 0px 0px 10px;
    }

    button {
        background: #215523;
        border-radius: 0px 10px 10px 0px;
        border: none;
        color: white;
        padding: 8px 12px;
        font-size: 18px;
    }

    @include breakpoint(tablet) {}

    @include breakpoint(phablet) {}

    @include breakpoint(mobile) {
        padding: 20px 6px 2px 6px;

        input {
            padding: 9px;
            width: 180px;
            font-size: 12px;
        }

        button {
            padding: 7px 8px;
            font-size: 14px;
        }
    }
}

.mg-village {
    background-color: #f4f4f8;
    min-height: 100vh;
    padding: 110px 40px 20px 40px;

    @media (max-width: 500px) {
        padding: 86px 10px 20px 10px;
    }

    &-section {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        // margin: 80px 280px;
        height: 100%;
        border: 1px solid #ccc;

        @media (max-width: 400px) {
            padding: 20px 10px;
        }
    }

    &-profile {
        position: relative;
    }
}

.mg-village_cover-picture {
    margin: -20px;

    @media (max-width: 400px) {
        margin: -20px -10px;
    }

    cursor: pointer;
    border-radius: 5px 5px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 280px;

    img {
        max-height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.mg-village_profile-picture {
    height: 194px;
    width: 196px;
    margin: auto;
    position: relative;
    cursor: pointer;
    background-color: white;
    padding: 5px;
    border-radius: 2px;
    //left: 25px;
    top: -75px;
    border-radius: 50%;

    img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.mg-village_profile-overview {
    margin-left: 40px;
    margin-top: -60px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        font-size: 16px;
        font-weight: 500;
    }

    @media (max-width: 480px) {
        margin-left: 10px;
        text-align: center;
    }
}

// see your village section
.see_village_section {
    margin: 60px 0px;
    padding-top: 30px;
    padding-bottom: 16px;

    h4 {
        font-size: 40px;
        font-weight: 500;
        text-align: center;
        padding-bottom: 24px;
    }

    @include breakpoint(tablet) {
        padding: 12rem;
    }

    @include breakpoint(phablet) {
        padding: 8rem;
    }

    @include breakpoint(mobile) {
        padding: 1rem;
        margin: 40px 0px;

        h4 {
            font-size: 26px;
            padding-bottom: 16px;
        }
    }
}

.see_village_section_search {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin: auto;
    width: 470px;

    button {
        background: #337ab7;
        padding: 6px 18px;
        font-size: 18px;
        border: none;
        border-radius: 26px;
        color: white;
        font-weight: 500;
    }
}

.mg_village_result_area {
    margin: 80px 280px;
}

.search_village_input_text {
    width: 300px;
    font-size: 16px;
}

@include breakpoint(mobile) {
    .see_village_section_search {
        width: 360px;
        padding: 4px 20px;
        gap: 8px;

        button {
            padding: 4px 14px;
            font-size: 14px;
        }
    }

    .search_village_input_text {
        font-size: 13px;
    }

    .mg_village_result_area {
        margin: 12px 10px;
    }
}

.hhero_see-btn {
    background-color: #337ab7;
    border-color: #337ab7;
    border: none;

    &:hover {
        color: rgb(255, 255, 255);
        background-color: #4f8bbf;
    }
}

// green section MG
.green_section_quote {
    background-color: #a2bd5e;
    padding: 60px;

    h1 {
        color: #fff;
        font-weight: 500;
        font-size: 38px;
        font-family: montserrat;
        font-style: normal;
        line-height: 1.3;
        text-align: center;
    }
}

.mg_busines_section {
    padding: 14px 0px 0px 0px;
    top: 10px;

    h1 {
        color: #000000c9;
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        font-family: montserrat;
        font-style: normal;
        padding: 40px;
    }
}

@media (min-width: 800px) and (max-width: 1024px) {
    .mg_busines_section {
        margin-top: -24px;
    }

    .green_section_quote>h1 {
        font-size: 30px;
    }
}

@include breakpoint(tablet) {
    .mg_busines_section {
        padding: 20px 0px 0px 0px;

        h1 {
            font-size: 32px;
            padding: 24px;
        }
    }

    .green_section_quote {
        padding: 40px 20px;
    }

    .green_section_quote>h1 {
        font-size: 32px;
    }
}

@include breakpoint(mobile) {
    .mg_busines_section {
        padding: 1px 0px 0px 0px;
        top: 14px;

        h1 {
            font-size: 26px;
            padding: 16px;
        }
    }

    .green_section_quote>h1 {
        font-size: 28px;
    }

    .green_section_quote {
        padding: 30px 20px;
    }
}

.mg_busines_section-img {
    width: 50%;
    background-position: center;
    background-size: cover;

    //margin-right: -320px;
    @media (min-width:769px) and (max-width: 1200px) {
        width: 90vw;
        //margin-right: -40px;
    }

    @media (max-width: 768px) {
        width: 95vw;
        margin: 3rem 0 0 0;
    }

    @media (max-width: 500px) {
        width: 94vw;
        margin: 2rem 0 0 0;
    }

    @media (max-width: 375px) {
        height: 166px;
        width: 94vw;
        margin: 2rem 0 0 0;
    }
}

.mg_busines_section-info {
    text-align: left;
    width: 50%;
    padding-right: 64px;
    float: left;
    top: -100px;

    &--text {
        margin-top: 16px;
        font-size: 22px;
        color: #000000c9;
        font-weight: 400;
        line-height: 1.5;
    }

    @include breakpoint(tablet) {
        .mg_busines_section-info--text {
            font-size: 18px !important;
        }

        width: 60%;
        clear: both;
        text-align: center;
        font-size: 16px;
        padding-right: 30px;
        line-height: 26px;
        top: -60px;
    }

    @include breakpoint(mobile) {
        .mg_busines_section-info--text {
            font-size: 18px;
        }

        width: 100%;
        padding: 14px;
        clear: both;
        text-align: center;
        font-size: 18px;
        top: -24px;
    }
}

//Feature of MG section
.features {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;

    @include breakpoint(phablet) {
        padding: 5rem 0;
    }

    @include breakpoint(mobile) {
        padding: 0;
    }

    &-button {
        height: 5rem;
        width: 10rem;
        font-size: 2rem;
        border: none;
        box-shadow: 2px 2px 40px 10px rgba(230, 230, 230, 0.6);
        border-radius: 50%;
        transition: 200ms;
        color: #aaa;

        &:hover {
            box-shadow: 2px 2px 40px 10px #d9ddd999;
            color: #333;
        }
    }

    &-card {
        align-items: center;
        margin: 3rem 2rem 0 2rem;
        transition: 200ms;
        padding: 2rem;
        cursor: pointer;
        perspective: 10rem;
        border-radius: 10px;
        border: 1px solid transparent;

        @include breakpoint(tablet) {
            flex-direction: column;
            height: auto;
        }

        @include breakpoint(phablet) {
            flex-direction: column;
            margin: 0;
            padding: 2rem;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            margin: 0 0 14px 0;
            padding: 1rem;
            border: 1px solid #0000000d;
            border-radius: 8px;
        }

        &-details {
            padding: 6rem 4rem;

            @include breakpoint(tablet) {
                padding: 3rem 10rem;
            }

            @include breakpoint(phablet) {
                padding: 1rem 6rem;
            }

            @include breakpoint(mobile) {
                padding: 0 15px;
            }
        }

        &-image-container {
            position: relative;

            @include breakpoint(tablet) {
                height: auto;
                padding: 3rem 10rem;
            }

            @include breakpoint(phablet) {
                padding: 3rem 6rem;
                height: auto;
            }

            @include breakpoint(mobile) {
                padding: 2rem 15px;
            }
        }

        &-image {
            position: absolute;
            top: 4rem;
            left: 0;
            width: 42rem;
            height: 52rem;

            @media (min-width: 1024px) and (max-width: 1280px) {
                width: 36rem;
                height: 50rem;
            }

            @media (min-width: 900px) and (max-width: 1024px) {
                width: 34rem;
                height: 48rem;
            }

            @include breakpoint(tablet) {
                width: 38rem;
                height: 50rem;
                top: 4rem;
            }

            @include breakpoint(phablet) {
                width: 40rem;
                height: 40rem;
            }

            @include breakpoint(mobile) {
                width: 38rem;
                height: 46rem;
                // position: relative;
                // top: 0;
                // margin-bottom: 20px;
            }

            &-1 {
                background: url("../components/pages/MeraGaonWebpage/components/Images/post.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &-2 {
                background: url("../components/pages/MeraGaonWebpage/components/Images/offer.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &-3 {
                background: url("../components/pages/MeraGaonWebpage/components/Images/anouncement.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &-4 {
                background: url("../components/pages/MeraGaonWebpage/components/Images/asp.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &-5 {
                background: url("../components/pages/MeraGaonWebpage/components/Images/event.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &-6 {
                background: url("../components/pages/MeraGaonWebpage/components/Images/csoon.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        &-special-img {
            display: none;

            @include breakpoint(phablet) {
                display: block;
                height: 30rem;
                width: 60rem;
            }

            @include breakpoint(mobile) {
                display: block;
                height: 38rem;
                width: 40rem;
            }

            margin: 4rem auto 0 auto;
            background: url("../components/pages/MeraGaonWebpage/components/Images/csoon.png");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &-text {
            margin-top: 1rem;
        }

        &:hover {
            box-shadow: 2px 2px 40px 10px rgba(230, 240, 230, 0.6);

            @include breakpoint(phablet) {
                box-shadow: none;
            }

            @include breakpoint(mobile) {
                box-shadow: none;
            }
        }

        &-active {
            background-color: #edf1e4;
            box-shadow: 2px 2px 40px 10px #e6f0e699;
            border: 1px solid rgba(220, 240, 220, 1);

            @include breakpoint(tablet) {
                // left: 15%;
            }

            @include breakpoint(phablet) {
                background-color: #edf1e4;
                border: 1px solid transparent;
            }

            @include breakpoint(mobile) {
                background-color: #edf1e4;
                border: 1px solid rgba(220, 240, 220, 1);
            }
        }
    }

    &-list {
        list-style: none;
        display: flex;
        font-size: 1.8rem;
        justify-content: center;
        width: 70%;
        margin: 4rem auto 0 auto;

        @include breakpoint(phablet) {
            width: 90%;
            margin: 3rem auto 0 auto;
        }

        @include breakpoint(mobile) {
            width: 90%;
            margin: 3rem auto 0 auto;
        }

        & li {
            margin: 0 3rem;
            cursor: pointer;
            border-bottom: 2px solid transparent;

            @include breakpoint(phablet) {
                margin: 0 2rem;
            }

            @include breakpoint(mobile) {
                margin: 0 1rem;
            }
        }

        & li:hover {
            color: $positive-green;
        }

        li.features-list-item-active {
            border-bottom: 2px solid $positive-green;
        }
    }
}

.renderImage {
    display: none;
}

.renderImageCard {
    display: block;

    @media (max-width: 767px) {
        display: none;
    }
}

/***** modified css of carousal package for Mg feature*******/
.use-mg-feature-slider {
    display: none;

    .carousel .slider.animated {
        transition: none;
    }

    .carousel .slide {
        background: transparent;
    }

    .carousel .slide img {
        width: 50%;

        @media (min-width: 426px) and (max-width: 767px) {
            width: 40%;
        }
    }

    .carousel.carousel-slider .control-arrow {
        opacity: 1 !important;
        height: 40px;
        border-radius: 4px;
        align-items: center;
        top: 150px;
        background: transparent;

        @media (min-width: 447px) and (max-width: 767px) {
            margin: 0px 60px;
            top: 200px;
        }

    }

    .carousel.carousel-slider .control-prev {
        color: red;

        &::before {
            border-right: 8px solid #a2bd5e;
        }
    }

    .carousel.carousel-slider .control-next {
        color: red;

        &::before {
            border-left: 8px solid #a2bd5e;
        }
    }

    .carousel.carousel-slider .control-arrow:hover {
        background: transparent !important;
    }

    @media (max-width: 767px) {
        max-width: 100%;
        display: block;
    }

    h4 {
        font-size: 24px;
        padding: 24px 10px 10px 10px;
        color: #333;
    }

    p {
        font-size: 16px;
        color: #333;
    }
}