//testimonial section
.mg_testimonial_heading {
    padding: 4px 4px 40px 4px;
    width: 55vw;
    margin: 24px auto;
    color: #000000c9;
    font-size: 40px;
    text-align: center;
    @media (min-width: 901px) and (max-width: 1024px) {
        margin: 20px auto;
        font-size: 30px;
        padding: 4px 8px;
        width: 80vw;
    }
    @include breakpoint(tablet) {
        margin: 20px auto;
        font-size: 30px;
        padding: 4px 8px;
        width: 80vw;
    }

    @include breakpoint(mobile) {

        margin: 20px auto;
        font-size: 26px;
        padding: 4px 8px;
        width: 100vw;

    }
}



.testimonial-section {
    margin-top: 60px;

    @include breakpoint(largeDesktop) {
        margin-top: 60px;
    }

    @include breakpoint(tablet) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .testimonial-wrapper {
        margin: auto auto auto auto;

        @include breakpoint(hdLaptop) {
            width: 60%;
            margin: auto auto auto auto;
        }

        @include breakpoint(largeDesktop) {
            width: 75%;
        }
    }

    .testimonial-container {
        position: relative;
        width: 90%;
        height: 450px;
        margin: auto;
        margin-top: 4rem;

        @include breakpoint(mobile) {
            position: static;
            height: auto;
            min-height: 320px;
            max-height: auto;
        }

        @include breakpoint(tablet) {
            height: auto;
            min-height: 300px;
        }

        .first-card,
        .second-card,
        .third-card {
            position: absolute;
            background-color: white;
            border-radius: 8px;
            z-index: 5;
        }

        .first-card,
        .third-card {
            height: 350px;
            top: 25px;
            width: 24%;
            opacity: 0.5;
            padding: 25px 15px 15px 15px;

            img.quoteIcon {
                width: 30px;
                height: 30px;
            }

            .testimonial-text {
                margin-top: 50px;
                max-height: 40%;
                overflow: hidden;
            }

            .info-wrapper {
                margin-top: 40px;
                display: grid;
                grid-template: 50px / 40px auto;
                grid-column-gap: 20px;

                img {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                }
            }

            @include breakpoint(laptop) {
                height: auto;

                .testimonial-text {
                    margin-top: 15px;
                    max-height: 130px;
                    overflow: hidden;
                }

                .info-wrapper {
                    margin-top: 15px;
                }
            }

            @include breakpoint(phablet) {
                display: none;
            }

            @include breakpoint(tablet) {
                height: auto;

                .testimonial-text {
                    max-height: 80px;
                    overflow: hidden;
                }

                .info-wrapper {
                    grid-column-gap: 0;

                    img {
                        height: 20px;
                        width: 20px;
                    }

                    .name p {
                        font-size: 8px;
                    }
                }
            }
        }

        .third-card {
            right: 0;
        }

        .active-card {
            height: 380px;
            width: 60%;
            left: 50%;
            z-index: 6;
            transform: translateX(-50%);
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
            padding: 0 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .quoteIcon {
                height: 40px;
                width: 40px;
                margin-bottom: 2rem;
                border-radius: 20%;
            }

            p.testimonial-text {
                font-size: 1.8rem;
            }

            .info-wrapper {
                display: grid;
                margin-top: 0px;
                grid-template: 50px / 65px auto;
                align-items: center;
                grid-column-gap: 3%;
            }

            img {
                border-radius: 50%;
                height: 65px;
                width: 65px;
            }

            div.name {
                h5 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1.5rem;
                }
            }

            @include breakpoint(laptop) {
                padding: 20px 30px;
                height: 330px;

                .quoteIcon {
                    height: 40px;
                    width: 40px;
                }

                .info-wrapper {
                    margin-top: 40px;

                    img {
                        height: 45px;
                        width: 45px;
                    }
                }
            }

            @include breakpoint(phablet) {
                width: 95%;
                margin: auto;
                height: auto;
                padding: 15px;

                .quoteIcon {
                    height: 30px;
                    width: 30px;
                    display: inline-block;
                    margin-bottom: 15px;
                }

                .info-wrapper {
                    margin-top: 0;
                    grid-column-gap: 0%;

                    img {
                        height: 52px;
                        width: 52px;
                    }
                }

                div.name h5 {
                    font-size: 2rem;
                }
            }

            @include breakpoint(tablet) {
                height: 290px;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .testimonial-btns {
        .wrapper {
            margin: auto;
            width: 100px;
            display: flex;
            justify-content: space-between;

            .arrow-btn {
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                // color: white;
                height: 42px;
                width: 42px;
                border-radius: 50%;
                box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.3);
                transition: all 0.2s linear;
                background-color: #fff;
                color: #88ad49;
                font-size: 16px;
                cursor: pointer;
                border: 1px solid #88ad496e;
                font-size: 16px;
                cursor: pointer;

                &:hover {
                    border: 1px solid #88ad49d4;
                }
            }

            @include breakpoint(mobile) {
                width: 100px;
            }
        }
    }

    @include breakpoint(tablet) {
        .background--1 {
            height: 70rem;
            top: 0rem;
        }
    }

    @include breakpoint(mobile) {
        .background--1 {
            display: none;
        }
    }
}

.animate-card {
    & * {
        animation: fadeInCard 0.6s ease-in-out 0s 1 normal forwards;
    }
}

@keyframes fadeInCard {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}