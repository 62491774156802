@import "colors";
@import "mixins";

.highlight-loader {
    display: flex;
    background: white;
    border-radius: 50px;
}

.highlight-loader-item {
    width: 26px;
    height: 26px;
    border-radius: 50px;
    animation: load 1.6s ease-in-out infinite;
}

.highlight-loader-item:nth-child(1) {
    animation-delay: -1.4s;
    background-color: #5369D8;
    box-shadow: 0 0 50px #5369D8;
}

@keyframes load {

    0%,
    30%,
    100% {
        transform: scale(0.4);
    }

    20% {
        transform: scale(1.2);
    }
}

.loader-weekly {
    margin: auto;
    border: 6px solid #e8e8e8;
    border-radius: 50%;
    border-top: 6px solid #5362ed;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.weekly-error-wrapper {
    max-width: 400px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 20px 30px;

    img {
        width: 200px;
        height: 200px;
    }

    p {
        margin: 15px 0px;
        text-align: center;
        color: #444242;
        font-weight: 500;
    }
}

.weekly-error {
    margin-top: 30px;

    button {
        background: #890707;
        border: none;
        color: white;
        border-radius: 3px;
        padding: 4px 12px;

        i {
            padding-right: 5px;
        }
    }
}

// highlight event css
.glow-on-hover {
    z-index: 0;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(50deg, #f7f2e7, #ccac4a);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.weekOne_new_reward {
    position: absolute;
    top: 17%;
    width: 100%;

    img {
        height: 120px;
        width: 115px;
        margin: auto;
    }

    fieldset {
        border: 1px solid #FFE69A;
        border-radius: 20px;
        width: 95%;
        margin: auto;
    }

    legend {
        border: 1px solid #c7d99f;
        padding: 4px 12px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 600;
        background-color: #FFE69A;
        width: 85%;
        margin: 0px;
        display: inline-block;
        text-align: center;
        color: #2D6535;
    }
}

.weekOne_new_reward_progressBar {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 12px 4px 4px 8px;

    p {
        font-size: 14px;
        color: white;
        font-weight: 600;
        padding: 4px 4px 2px 4px;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        color: #ffe69a;
        padding: 4px 4px 2px 4px;
    }
}

.progress-bar-container {
    width: 100%;
    background-color: #f5de99;
    height: 20px;
    border-radius: 20px;
    margin: 10px 0;
}

.progress-bar {
    height: 20px;
    background-color: #DD1F47;
    border-radius: 20px;
    transition: width 0.5s ease-in-out; /* Add transition for smooth animation */
    box-shadow: 0 0 4px rgba(5, 25, 5, 0.7);
}