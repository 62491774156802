@import "colors";
@import "mixins";

.heading-container {
    &--heading {
        @include breakpoint(tablet) {
            text-align: center;
        }

        @include breakpoint(phablet) {
            padding: 4rem 4rem 0 4rem;
        }

        @include breakpoint(mobile) {
            text-align: left;
            padding: 0;
        }
    }

    &--text {
        text-align: left;
        // background-color: red;
        padding: 4rem 4rem 0 4rem;

        @include breakpoint(tablet) {
            padding: 4rem 4rem 0 4rem;
        }

        @include breakpoint(phablet) {
            padding: 3rem 0 0 0;
        }

        @include breakpoint(mobile) {
            padding: 2rem 0 0 0;
        }
    }
}

.department {
    &-container {
        padding: 4rem;
    }

    &-card {
        min-height: 25rem;
        text-align: center;
        background-color: $white;
        padding: 4rem;
        border-radius: 1rem;
        @include shadow(5px 5px 25px 0 rgba(46, 61, 73, 0.2));
        transition: 200ms;
        cursor: pointer;
        border-top-style: solid;
        border-top-width: 3px;

        &:hover {
            @include shadow(2px 4px 8px 0 rgba(46, 61, 73, 0.2));
            transform: translateY(-2px);
        }

        &:hover .department-icon {
            animation: icon-animation 1400ms ease-in infinite forwards;
        }

        & > p {
            margin-top: 2rem;
        }
    }

    &-icon {
        font-size: 2.4rem;
        margin-top: 1rem;
        transition: 300ms;
        color: $text-primary;
    }

    &-engineering {
        border-top-color: orangered;
    }

    &-operations {
        border-top-color: $positive-green;
    }

    &-sales {
        border-top-color: yellowgreen;
    }

    &-misc {
        border-top-color: midnightblue;
    }
}

.background--3 {
    border-radius: 4px;
    height: 100rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -18rem;
    @include trans(translateX(-50%));
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
    -webkit-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
    -moz-clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 80%);
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 150rem;
        margin-top: -10rem;
        clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
        -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
        -moz-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    }

    @include breakpoint(phablet) {
        height: 150rem;
        margin-top: -8rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }

    @include breakpoint(mobile) {
        height: 300rem;
        margin-top: -8rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

.background--4 {
    border-radius: 4px;
    height: 85rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -15rem;
    @include trans(translateX(-50%));
    clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);
    -webkit-clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);
    -moz-clip-path: polygon(0 25%, 100% 0, 100% 75%, 0% 100%);
    background-color: rgba(245, 240, 245, 0.6);

    @include breakpoint(tablet) {
        height: 60rem;
        margin-top: -3rem;
        clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
        -webkit-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
        -moz-clip-path: polygon(0 8%, 100% 0, 100% 92%, 0% 100%);
    }

    @include breakpoint(phablet) {
        height: 60rem;
        margin-top: -5rem;
        clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
        -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
        -moz-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
    }

    @include breakpoint(mobile) {
        height: 85rem;
        margin-top: -5rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

@keyframes icon-animation {
    0% {
        transform: translateX(-10px);
        opacity: 1;
    }
    80% {
        transform: translateX(10px);
        opacity: 0;
    }
    100% {
        transform: translateX(10px);
        opacity: 0;
    }
}

.icon-container {
    padding: 2rem 4rem;
}

.tech-icon {
    &-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 3rem;
    }

    width: 6rem;
    height: 6rem;
    transition: 150ms;
    cursor: pointer;
    align-self: center;

    &:hover {
        filter: grayscale(10);
    }

    &-name {
        margin-top: 1rem;
    }
}

.jobs {
    list-style: none;

    & > li {
        background-color: $white;
        padding: 2.4rem 4rem;
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ddd;

        @include breakpoint(tablet) {
            // flex-direction: column;
        }

        @include breakpoint(phablet) {
            // flex-direction: column;
        }

        @include breakpoint(mobile) {
            padding: 2.4rem 2rem;
            width: 95%;
        }

        & > span {
            display: flex;
            flex-direction: column;
        }

        & > a {
            cursor: pointer;
            font-size: 1.6rem;
            font-weight: bold;
            border-radius: 2px;
            padding: 0.5rem 2rem;
            color: $white;
            background-color: $theme-green;
            border: none;
            text-transform: uppercase;
            transition: all 200ms;

            &:hover {
                transform: translateY(-1px);
                box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

// .screenshot {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     @include breakpoint(tablet) {
//         // flex-direction: column;
//     }

//     @include breakpoint(phablet) {
//         flex-direction: column;
//     }

//     @include breakpoint(mobile) {
//         flex-direction: column;
//     }
//     &-text {
//         &-container {
//             height: 100%;
//             // width: 30%;
//             padding: 8rem 8rem 0 0;

//             @include breakpoint(tablet) {
//                 padding: 0 8rem;
//             }

//             @include breakpoint(phablet) {
//                 padding: 0 3rem;
//             }

//             @include breakpoint(mobile) {
//                 padding: 0 1rem;
//             }
//         }
//     }

//     &-img {
//         &-container {
//             position: relative;
//             max-width: 100%;
//         }
//         &-browser {
//             width: 75rem;
//             height: 50rem;
//             background: url(../images/laptop.png);
//             background-position: center;
//             background-size: contain;
//             background-repeat: no-repeat;
//             margin: 0 auto;
//             @include breakpoint(tablet) {
//                 width: 60rem;
//                 height: 50rem;
//             }

//             @include breakpoint(phablet) {
//                 width: 55rem;
//                 height: 40rem;
//             }

//             @include breakpoint(mobile) {
//                 width: 40rem;
//                 height: 30rem;
//             }
//             // box-shadow: 4px 4px 40px 20px rgba(245, 240, 245, 0.6);
//         }

//         &-phone {
//             position: absolute;
//             bottom: 2rem;
//             left: -4rem;
//             width: 20rem;
//             height: 30rem;
//             background: url(../images/android.png);
//             background-position: center;
//             background-size: contain;
//             background-repeat: no-repeat;

//             @include breakpoint(tablet) {
//                 left: 5%;
//             }

//             @include breakpoint(phablet) {
//                 width: 20rem;
//                 height: 25rem;
//             }

//             @include breakpoint(mobile) {
//                 width: 10rem;
//                 height: 16rem;
//             }
//         }
//     }
// }

.hire-steps {
    // background-color: red;
    width: 75%;
    margin: 0 auto;
    padding: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(tablet) {
        // left: 15%;
    }

    @include breakpoint(phablet) {
        // width: 20rem;
        // height: 30rem;
    }

    @include breakpoint(mobile) {
        width: 100%;
    }

    & .step {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // height: 14rem;
    }

    & .step-circle {
        background-color: $theme-green;
        height: 6rem;
        width: 6rem;
        padding: 1rem 2rem;
        border-radius: 50%;
        font-size: 3.2rem;
        text-align: center;
        font-weight: bolder;
        color: #eee;
    }

    & .step-name {
        display: inline-block;
        background-color: $theme-green;
        margin-top: 2rem;
        border-radius: 4px;
        color: #eee;
        padding: 1rem;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bolder;
        text-align: center;
        vertical-align: middle;

        &::after {
            content: "";
            position: absolute;
            background-color: $theme-green;
            width: 1.5rem;
            height: 1.5rem;
            top: -10%;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            border-radius: 4px;
        }
    }

    & > .step-join {
        display: block;
        border-top: 4px dotted #999;
        width: 20rem;
    }
}

.values {
    @include breakpoint(tablet) {
        // flex-direction: column;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
    }

    &-text {
        margin-top: 1rem;
    }

    &-rev {
        flex-direction: row-reverse;
        margin-top: 8rem;

        @include breakpoint(tablet) {
            // flex-direction: column;
        }

        @include breakpoint(phablet) {
            flex-direction: column;
            margin-top: 6rem;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            margin-top: 3rem;
        }
    }

    &-text-container {
        padding: 4rem;

        @include breakpoint(tablet) {
            // flex-direction: column;
        }

        @include breakpoint(phablet) {
            padding: 3rem;
        }

        @include breakpoint(mobile) {
            padding: 2rem 1rem;
        }
    }

    &-img-container {
        padding: 2rem 4rem;

        @include breakpoint(tablet) {
            // flex-direction: column;
        }

        @include breakpoint(phablet) {
            padding: 2rem 2rem;
        }

        @include breakpoint(mobile) {
            padding: 2rem 0;
        }
    }

    &-img {
        height: 25rem;

        @include breakpoint(mobile) {
            height: 30rem;
        }
    }
}

// .features {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 3rem 0;

//     @include breakpoint(phablet) {
//         padding: 5rem 0;
//     }

//     @include breakpoint(mobile) {
//         padding: 0;
//     }

//     &-button {
//         height: 5rem;
//         width: 10rem;
//         font-size: 2rem;
//         border: none;
//         box-shadow: 2px 2px 40px 10px rgba(230, 230, 230, 0.6);
//         border-radius: 50%;
//         transition: 200ms;
//         color: #aaa;

//         &:hover {
//             box-shadow: 2px 2px 40px 10px rgba(220, 220, 220, 1);
//             color: #333;
//         }
//     }

//     &-card {
//         align-items: center;
//         margin: 3rem 2rem 0 2rem;
//         transition: 200ms;
//         padding: 2rem;
//         cursor: pointer;
//         perspective: 10rem;
//         border-radius: 10px;
//         border: 1px solid transparent;

//         @include breakpoint(tablet) {
//             flex-direction: column;
//             height: auto;
//         }

//         @include breakpoint(phablet) {
//             flex-direction: column;
//             margin: 0;
//             padding: 2rem;
//         }

//         @include breakpoint(mobile) {
//             flex-direction: column;
//             margin: 0;
//             padding: 1rem;
//         }

//         &-details {
//             padding: 6rem 4rem;

//             @include breakpoint(tablet) {
//                 padding: 3rem 10rem;
//             }

//             @include breakpoint(phablet) {
//                 padding: 1rem 6rem;
//             }

//             @include breakpoint(mobile) {
//                 padding: 0 15px;
//             }
//         }

//         &-image-container {
//             position: relative;

//             @include breakpoint(tablet) {
//                 height: auto;
//                 padding: 3rem 10rem;
//             }

//             @include breakpoint(phablet) {
//                 padding: 3rem 6rem;
//                 height: auto;
//             }

//             @include breakpoint(mobile) {
//                 padding: 2rem 15px;
//             }
//         }

//         &-image {
//             position: absolute;
//             top: 4rem;
//             left: 0;
//             width: 60rem;
//             height: 60rem;

//             @include breakpoint(tablet) {
//                 width: 60rem;
//                 height: 50rem;
//             }

//             @include breakpoint(phablet) {
//                 width: 40rem;
//                 height: 40rem;
//             }

//             @include breakpoint(mobile) {
//                 width: 30rem;
//                 height: 40rem;
//             }

//             &-1 {
//                 background: url(../images/androidOffline.png);
//                 background-position: center;
//                 background-size: contain;
//                 background-repeat: no-repeat;
//             }
//             &-2 {
//                 background: url(../images/androidPaper.png);
//                 background-position: center;
//                 background-size: contain;
//                 background-repeat: no-repeat;
//             }
//             &-3 {
//                 top: 0;
//                 left: -5rem;
//                 background: url(../images/laptopIP.png);
//                 background-position: center;
//                 background-size: contain;
//                 background-repeat: no-repeat;
//             }
//         }

//         &-special-img {
//             display: none;

//             @include breakpoint(phablet) {
//                 display: block;
//                 height: 30rem;
//                 width: 65rem;
//             }

//             @include breakpoint(mobile) {
//                 display: block;
//                 height: 25rem;
//                 width: 45rem;
//             }

//             margin: 1rem auto 0 auto;
//             background: url(../images/laptopIP.png);
//             background-position: center;
//             background-size: contain;
//             background-repeat: no-repeat;
//         }
//         // &-heading {
//         //     font-weight: bold;
//         // }

//         &-text {
//             margin-top: 1rem;
//         }

//         &:hover {
//             box-shadow: 2px 2px 40px 10px rgba(230, 240, 230, 0.6);

//             @include breakpoint(phablet) {
//                 box-shadow: none;
//             }

//             @include breakpoint(mobile) {
//                 box-shadow: none;
//             }
//         }

//         &-active {
//             background-color: rgba(220, 240, 220, 0.4);
//             // box-shadow: 2px 2px 40px 10px rgba(230, 240, 230, .6);
//             border: 1px solid rgba(220, 240, 220, 1);

//             @include breakpoint(tablet) {
//                 // left: 15%;
//             }

//             @include breakpoint(phablet) {
//                 background-color: transparent;
//                 border: 1px solid transparent;
//             }

//             @include breakpoint(mobile) {
//                 background-color: transparent;
//                 border: 1px solid transparent;
//             }
//         }

//         &-image-active {
//             animation: featureIn 600ms ease-in-out 1 forwards;
//         }
//     }

//     &-list {
//         list-style: none;
//         display: flex;
//         font-size: 1.8rem;
//         // border-bottom: 1px solid #ccc;
//         justify-content: center;
//         width: 70%;
//         margin: 4rem auto 0 auto;

//         @include breakpoint(phablet) {
//             width: 90%;
//             margin: 3rem auto 0 auto;
//         }

//         @include breakpoint(mobile) {
//             width: 90%;
//             margin: 3rem auto 0 auto;
//         }

//         & li {
//             margin: 0 3rem;
//             cursor: pointer;
//             border-bottom: 2px solid transparent;

//             @include breakpoint(phablet) {
//                 margin: 0 2rem;
//             }

//             @include breakpoint(mobile) {
//                 margin: 0 1rem;
//             }
//         }

//         & li:hover {
//             color: $positive-green;
//             // border-bottom: 2px solid $positive-green;
//         }

//         li.features-list-item-active {
//             border-bottom: 2px solid $positive-green;
//             // color: $positive-green;
//         }
//     }
// }

@keyframes featureIn {
    0% {
        // transform: scale(0.9);
        transform: translate(10rem);
        opacity: 0;
    }
    100% {
        transform: translate(0);
        // transform: scale(1);
        opacity: 1;
    }
}

.background--5 {
    border-radius: 4px;
    height: 95rem;
    width: 100vw;
    z-index: -10;
    position: absolute;
    left: 50%;
    margin-top: -10rem;
    @include trans(translateX(-50%));
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 85%);
    -webkit-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 85%);
    -moz-clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 85%);
    background-color: rgba(245, 240, 245, 0.6);
    // background-color: #fff2e6;

    @include breakpoint(tablet) {
        height: 80rem;
        margin-top: -5rem;
        clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
        -webkit-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
        -moz-clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 95%);
    }

    @include breakpoint(phablet) {
        height: 90rem;
        margin-top: -5rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }

    @include breakpoint(mobile) {
        height: 100rem;
        margin-top: -2rem;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -moz-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}

.job {
    padding: 8rem 0 0 0;

    .back-button {
        font-size: 1.6rem;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid transparent;
        outline: none;
        margin-bottom: 2rem;
        padding: 1rem 1rem 0 1rem;

        & > i {
            margin-left: -5px;
            margin-right: 5px;
            transition: 200ms;
        }

        &:hover > i {
            margin-left: -10px;
            margin-right: 10px;
        }

        &:hover {
            border-bottom: 1px solid #ccc;
            color: #444;
        }
    }

    &-title {
        position: sticky;
        background-color: $white;
        margin-top: 2rem;
        z-index: 10;
    }

    &-description {
        padding: 2rem 4rem 4rem 4rem;

        // &-text {
        //     // min-height: 70vh;
        //     // overflow: scroll;
        // }

        @include breakpoint(phablet) {
            padding: 4rem;
        }

        @include breakpoint(mobile) {
            padding: 2rem;
        }
    }

    &-form {
        padding: 4rem;
        background-color: rgba(242, 245, 242, 0.6);
        border-radius: 5px;

        @include breakpoint(phablet) {
            padding: 4rem;
        }

        @include breakpoint(mobile) {
            margin: 2rem;
        }
        // position: fixed;
        // right: 40rem;
        // top: 22rem;
        // width: 45rem;
    }

    &-input {
        margin: 3rem 0 0 0;
        font-size: 1.6rem;
        padding: 1rem 1.5rem;
        border: none;
        outline: none;
        border-radius: 4px;
        width: 100%;
        border: 1px solid transparent;
        background-color: $white;

        &:placeholder-shown > label {
            opacity: 0;
        }

        &::placeholder {
            color: #aaa;
        }

        &:focus > label {
            opacity: 0;
        }

        @include breakpoint(mobile) {
            font-size: 2.6rem;
        }
    }

    &-input.error {
        border: 1px solid crimson;
    }

    .resume {
        display: none;

        &-label {
            color: #aaa;
            font-weight: 100;
            cursor: pointer;
        }
    }

    .filename {
        float: left;
        font-weight: 600;
        color: #666;
    }

    textarea.job-input {
        resize: vertical;
    }

    &-section {
        padding: 2rem;
        background-color: rgba(242, 245, 242, 0.6);
        border-radius: 5px;
        margin-bottom: 2rem;

        & ul {
            margin-top: 2rem;
            padding: 0 2rem;
        }
    }
}

.select-city {
    -webkit-appearance: none;
    color: #333;
    font-weight: 300;
    background-color: $white;
}

.submit-application-btn {
    padding: 1rem 3rem;
    margin-top: 2rem;
    background-color: $positive-green;
    color: $white;
    font-size: 2rem;
    text-transform: uppercase;
    float: right;
    transition: 200ms;
    border: none;
    outline: none;

    &:hover {
        border-radius: 4px;
        transform: translateY(-2px);
        box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.2);
    }
}

.mandatory::after {
    content: "*";
    display: inline;
    color: crimson;
    position: absolute;
    right: -10px;
    top: 2.4rem;
    font-size: 2rem;
}

.error-label {
    font-size: 1.2rem;
    color: crimson;
    font-style: italic;
    padding: 2rem 0 0 1rem;
}

.spin {
    -webkit-animation-name: spinner;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.stop-scrolling {
    overflow: hidden;
}

.alert {
    &-container {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(240, 240, 240, 0.8);
        z-index: 1000;
    }
    &-box {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 8rem 16rem;
        background-color: rgba(220, 240, 220, 0.2);
        border-radius: 1rem;
        z-index: 100000;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
        animation: modalIn 800ms ease-in-out 1 forwards;
    }
    &-icon {
        font-size: 6rem;
        color: $positive-green;
        // margin-bottom: 1rem;
        text-align: center;
    }
    &-close {
        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 3rem;
        background-color: transparent;
        border: none;
        transition: 150ms;
        // transform-origin: center;

        &:hover {
            transform: translateY(-1px);
            color: crimson;
        }
    }
}

@keyframes modalIn {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

.offer {
    &-text {
        padding: 4rem 15rem 0 15rem;

        @include breakpoint(tablet) {
            padding: 4rem 10rem 0 10rem;
        }

        @include breakpoint(phablet) {
            padding: 4rem 7rem 0 7rem;
        }

        @include breakpoint(mobile) {
            padding: 4rem 0 0 0;
        }
    }

    &-box {
        padding: 1rem 6rem;

        @include breakpoint(tablet) {
            padding: 1rem 6rem;
        }

        @include breakpoint(phablet) {
            padding: 1rem 4rem;
        }

        @include breakpoint(mobile) {
            padding: 1rem 2rem;
        }
    }

    &-icon {
        width: 4rem;
        height: 4rem;
        display: inline-block;
        margin-left: 1rem;
    }
}

.success_submission {
    //height: 100vh;
    // background-color: red;

    &-message {
        // background-color: rgba(220, 240, 220, .6);
        // border-radius: 10px;
        // height: 50%;
        text-align: center;
       // padding: 15rem;
       padding: 4rem 18rem 4rem 18rem;

        @include breakpoint(tablet) {
            padding: 12rem;
        }

        @include breakpoint(phablet) {
            padding: 8rem;
        }

        @include breakpoint(mobile) {
            padding: 5rem;
        }

        &-heading {
            font-size: 5rem;
        }

        &-text {
            margin-top: 3rem;
        }
    }
}

.not-found {
    font-size: 10rem;

    &-container {
        margin: 8.9rem auto 0 auto;
        height: 70vh;
    }

    &-2 {
        transform: rotate(20deg);
        color: crimson;
    }
}

.spaceship {
    position: absolute;
    // transform: rotate(30deg);
    animation: shakeSpaceship 200ms ease-in infinite;
}

@keyframes shakeSpaceship {
    0% {
        transform: rotate(4deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-4deg);
    }
}

.subscript {
    float: right;
    // font-style: italic;
}
